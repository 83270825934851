var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('LayoutCard',{attrs:{"title":_vm.getTitle,"haveFooter":true,"subtitle":"Bộ thẻ:","type":"ĐÁP ÁN","timeLeft":_vm.estimateTime,"disableActions":_vm.isProcessing},on:{"openDeleteDialog":function($event){_vm.showDialogDeleteCard = true},"back":_vm.handleBackPage,"backCard":_vm.handleBackCard,"clickRelearn":function($event){return _vm.handleNextCard(0)},"clickEasyType":function($event){return _vm.handleNextCard(1)},"clickGoodType":function($event){return _vm.handleNextCard(2)},"clickHardType":function($event){return _vm.handleNextCard(3)},"saveCard":_vm.handleClickSaveCard,"unSaveCard":_vm.handleClickUnSaveCard,"clickDelete":_vm.handleDeleteCloneCard,"clickCloneType":function($event){return _vm.handleNextCard(4)},"rightSingleTap":function($event){return _vm.handleNextCard(2)},"leftSingleTap":function($event){return _vm.handleNextCard(0)},"leftDoubleTap":_vm.handleBackCard,"rightDoubleTap":_vm.handlePlayAudio}},[(_vm.isProcessing)?_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"min-height":"calc(100vh - 480px)"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1):_c('v-layout',{staticClass:"d-flex flex-column align-center disable-copy",class:{ 'mb-28': _vm.$vuetify.breakpoint.smAndUp },staticStyle:{"min-height":"calc(100vh - 480px)"}},[(_vm.currentCard.typeCard == 1)?[_c('div',{staticClass:"justify-center d-flex mb-2"},[_c('p',{staticClass:"mean",class:{ mobile: _vm.$vuetify.breakpoint.smAndDown },domProps:{"innerHTML":_vm._s(_vm.currentCard.mean)}})]),_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"word",class:{
              mobile: _vm.$vuetify.breakpoint.smAndDown,
              'mt-1': _vm.$vuetify.breakpoint.smAndUp,
            },domProps:{"innerHTML":_vm._s(_vm.currentCard.word)}})]),_c('div',{staticClass:"audio d-flex-center",class:{
            mobile: _vm.$vuetify.breakpoint.smAndDown,
            'mt-2': _vm.$vuetify.breakpoint.smAndUp,
          }},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown && 'img-wrapper'},[(_vm.currentCard.audioLink !== null)?_c('img',{staticClass:"cursor-pointer",style:(_vm.$vuetify.breakpoint.smAndDown && {
                  width: '16px',
                  height: '16px',
                }),attrs:{"src":require("@/assets/images/volumn.svg")},on:{"click":_vm.handlePlayAudio}}):_vm._e()]),(_vm.currentCard.audioLink)?_c('audio',{ref:"audioPlayer",attrs:{"src":_vm.currentCard.audioLink,"muted":"","autoplay":"","type":"audio/*"}}):_vm._e(),(_vm.currentCard.spelling !== 'null')?_c('p',{staticClass:"spelling"},[_vm._v(" "+_vm._s(_vm.currentCard.spelling)+" ")]):_vm._e(),(_vm.currentCard.wordType !== 'null')?_c('p',{staticClass:"word-type",staticStyle:{"color":"#f56742"}},[_vm._v(" ("+_vm._s(_vm.currentCard.wordType)+") ")]):_vm._e()]),_c('div',{staticClass:"text-center",class:{
            'mobile mt-3': _vm.$vuetify.breakpoint.smAndDown,
            'mt-4': _vm.$vuetify.breakpoint.smAndUp,
          }},[_c('p',{staticClass:"example",domProps:{"innerHTML":_vm._s(_vm.currentCard.exampleFormatted)}})])]:(_vm.currentCard.typeCard == 4)?[_c('div',{staticClass:"mt-1 text-center"},[_c('p',{staticClass:"word word28",class:[
              _vm.currentCard.word == _vm.answerToLowerCase ? 'correct' : 'wrong',
              _vm.$vuetify.breakpoint.smAndDown ? 'mobile' : '',
            ],staticStyle:{"display":"flex","align-items":"center","gap":"5px","font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.answerToLowerCase)+" "),_c('v-icon',{class:_vm.currentCard.word == _vm.answerToLowerCase ? 'correct' : 'wrong'},[_vm._v("mdi-arrow-right")]),_vm._v(" "+_vm._s(_vm.currentCard.word)+" ")],1)]),_c('div',{staticClass:"audio d-flex-center",class:{
            mobile: _vm.$vuetify.breakpoint.smAndDown,
            'mt-2': _vm.$vuetify.breakpoint.smAndUp,
          }},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown && 'img-wrapper'},[(_vm.currentCard.audioLink !== null)?_c('img',{staticClass:"cursor-pointer",style:(_vm.$vuetify.breakpoint.smAndDown && {
                  width: '16px',
                  height: '16px',
                }),attrs:{"src":require("@/assets/images/volumn.svg")},on:{"click":_vm.handlePlayAudio}}):_vm._e()]),(_vm.currentCard.audioLink)?_c('audio',{ref:"audioPlayer",attrs:{"src":_vm.currentCard.audioLink,"muted":"","autoplay":"","type":"audio/*"}}):_vm._e(),(_vm.currentCard.spelling !== 'null')?_c('p',{staticClass:"spelling"},[_vm._v(" "+_vm._s(_vm.currentCard.spelling)+" ")]):_vm._e(),(_vm.currentCard.wordType !== 'null')?_c('p',{staticClass:"word-type",staticStyle:{"color":"#f56742"}},[_vm._v(" ("+_vm._s(_vm.currentCard.wordType)+") ")]):_vm._e()]),_c('p',{staticClass:"mean mean22 mt-3",class:{
            'mobile mb-3': _vm.$vuetify.breakpoint.smAndDown,
          },domProps:{"innerHTML":_vm._s(_vm.currentCard.mean)}}),_c('div',{staticClass:"text-center",class:{ 'mt-3': _vm.$vuetify.breakpoint.smAndUp }},[_c('p',{staticClass:"example",domProps:{"innerHTML":_vm._s(_vm.currentCard.exampleFormatted)}})])]:(_vm.currentCard.typeCard == 5)?[_c('p',{staticClass:"mean ft400",class:{ mobile: _vm.$vuetify.breakpoint.smAndDown },staticStyle:{"color":"#1bb763"},domProps:{"innerHTML":_vm._s(_vm.formatBreakLineInEditor(_vm.currentCard.meanFormatted))}}),_c('div',{staticClass:"text-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-1'},[_c('p',{staticClass:"word ft400 word28",class:{ mobile: _vm.$vuetify.breakpoint.smAndDown },staticStyle:{"color":"#3887fe"},domProps:{"innerHTML":_vm._s(_vm.currentCard.wordFormatted)}})]),_c('div',{staticClass:"text-center mt-3"},[_c('p',{staticClass:"example",domProps:{"innerHTML":_vm._s(_vm.currentCard.exampleFormatted)}})])]:[_c('div',{staticClass:"mt-1 text-center"},[_c('p',{staticClass:"word ft400 word28",class:{ 'mobile-type-23': _vm.$vuetify.breakpoint.smAndDown },staticStyle:{"color":"#1bb763"},domProps:{"innerHTML":_vm._s(_vm.currentCard.wordFormatted)}})]),_c('p',{staticClass:"mean ft400",class:{
            'mobile-type-23 mt-3 mb-3': _vm.$vuetify.breakpoint.smAndDown,
            'mt-1': _vm.$vuetify.breakpoint.smAndUp,
          },staticStyle:{"color":"#3887fe"},domProps:{"innerHTML":_vm._s(_vm.formatBreakLineInEditor(_vm.currentCard.meanFormatted))}}),_c('div',{staticClass:"text-center",class:{ 'mt-3': _vm.$vuetify.breakpoint.smAndUp }},[_c('p',{staticClass:"example",domProps:{"innerHTML":_vm._s(_vm.currentCard.exampleFormatted)}})])]],2),_c('DialogComponent',{attrs:{"showDialogValue":_vm.showDialogDeleteCard,"typeDialog":"delete"},on:{"closeDialog":function($event){_vm.showDialogDeleteCard = false},"confirmRequest":_vm.handleConfirmDeleteCard}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }