<template>
  <v-container fluid class="mt-5">
    <v-row>
      <v-col cols="12" md="3" sm="3" class="d-flex align-end">
        <v-menu>
          <template></template>
        </v-menu>
        <v-menu offset-y transition="slide-y-transition" location="end">
          <template v-slot:activator="{ attrs, on }">
            <img
              src="@/assets/icons/add-new.svg"
              alt=""
              class="ml-4"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-list style="min-width: 150px">
            <v-list-item link @click="handleShowDialogAddAdmin">
              <v-list-item-title>Thêm admin mới</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="handleShowDialogAddAdmin(true)">
              <v-list-item-title>Thêm admin phụ</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="handleShowDialogAddStudent">
              <v-list-item-title>Thêm học sinh mới</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="handleShowDialogManageClasses">
              <v-list-item-title>Quản lý lớp</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12" md="9" sm="9">
        <input
          :placeholder="'Tìm kiếm học sinh'"
          :class="$vuetify.breakpoint.smAndDown ? 'smd' : ''"
          v-model.trim="searchValues"
          @focus="$emit('focus')"
          autoComplete
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <TableComponentV3
          :items="filterAccountList"
          :classes="getAllClasses"
          :type="'manage-student-table'"
          @delete="handleShowDialogDeleteUser"
          @filterClass="handleFilterClass"
          @edit="handleShowDialogEditUser"
          @navigate="browseStudent"
          :isLoading="isLoading"
        />
      </v-col>
    </v-row>
    <DialogComponent
      typeDialog="confirm"
      description="Bạn có chắc chắn muốn xóa không?"
      :showDialogValue="showDialogDelete"
      @closeDialog="handleCloseDialog"
      @confirmRequest="handleConfirmDelete"
    />
    <DialogComponent
      typeDialog="add-admin"
      :showDialogValue="showDialogAddAdmin"
      @closeDialog="handleCloseDialog"
      :editAccount="editingAccount"
      :isAddingSubAdmin="isAddingSubAdmin"
    />
    <DialogComponent
      typeDialog="add-student"
      :showDialogValue="showDialogAddStudent"
      :itemsSelect="getAllClasses"
      @closeDialog="handleCloseDialog"
      :editAccount="editingAccount"
    />
    <DialogComponent
      typeDialog="manage-classes"
      :showDialogValue="showDialogManageClasses"
      :itemsSelect="getAllClasses"
      @closeDialog="handleCloseDialog"
      @deleteClass="openDeleteClassDialog"
      @editClass="handleEditClass"
    />
    <DialogComponent
      typeDialog="edit-class"
      title="Sửa tên lớp"
      :showDialogValue="showDialogEditClass"
      @closeDialog="handleCloseDialog"
      @confirmRequest="updateClassName"
    />
  </v-container>
</template>
<script>
import TableComponentV3 from "@/components/ui/TableComponentV3.vue";
import { mapGetters, mapActions } from "vuex";
import DialogComponent from "@/components/ui/DialogComponent.vue";
import { ACCOUNT_ROLE } from "@/constants/index";
// import { getLastDay } from "@/uttil";

export default {
  name: "ManageStudent",
  components: { DialogComponent, TableComponentV3 },
  data() {
    return {
      isLoading: false,
      searchValues: "",
      showDialogDelete: false,
      showDialogAddAdmin: false,
      showDialogAddStudent: false,
      showDialogManageClasses: false,
      showDialogEditClass: false,
      editingClassId: "",
      classIds: [],
      editingAccount: {},
      isAddingSubAdmin: false,
      ACCOUNT_ROLE,
    };
  },
  computed: {
    ...mapGetters("admin", [
      "getAllClassesName",
      "getAllClasses",
      "getAccountList",
    ]),
    filterAccountList() {
      let newAccountList = this.getAccountList.filter((item) => {
        if (
          item.fullName
            .toLowerCase()
            .includes(this.searchValues.toLowerCase()) ||
          item.className
            .toLowerCase()
            .includes(this.searchValues.toLowerCase()) ||
          item.userName.toLowerCase().includes(this.searchValues.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      if (this.classIds.length == 0) {
        return newAccountList.sort((a, b) => {
          if (a.role === b.role) {
            // If roles are the same, sort alphabetically by some property (e.g., fullName)
            const lastNameA = a.fullName.trim().split(" ").pop();
            const lastNameB = b.fullName.trim().split(" ").pop();
            const lastNameComparison = this.customSort(lastNameA).localeCompare(
              this.customSort(lastNameB)
            );
            if (lastNameComparison === 0) {
              // If last names are the same, sort alphabetically by the full names
              return a.fullName.localeCompare(b.fullName);
            }
            return lastNameComparison;
          }
          // Sort by role (1 comes before 0)
          return b.role - a.role;
        });
      } else {
        return newAccountList
          .filter((item) => this.classIds.includes(item.classId))
          .sort((a, b) => {
            if (a.role === b.role) {
              // If roles are the same, sort alphabetically by some property (e.g., fullName)
              const lastNameA = a.fullName.trim().split(" ").pop();
              const lastNameB = b.fullName.trim().split(" ").pop();
              const lastNameComparison = this.customSort(
                lastNameA
              ).localeCompare(this.customSort(lastNameB));
              if (lastNameComparison === 0) {
                // If last names are the same, sort alphabetically by the full names
                return a.fullName.localeCompare(b.fullName);
              }
              return lastNameComparison;
            }
            // Sort by role (1 comes before 0)
            return b.role - a.role;
          });
      }
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchAccounts();
    this.isLoading = false;
    await this.fetchClasses();
  },
  methods: {
    ...mapActions("admin", [
      "fetchClasses",
      "deleteClass",
      "updateClass",
      "fetchAccounts",
      "deleteAccount",
    ]),
    customSort(lastName) {
      return lastName.toLowerCase();
    },
    handleSearch() {},
    async updateClassName(newClassName) {
      await this.updateClass({
        classId: this.editingClassId,
        newClassName: newClassName,
      });
      await this.fetchClasses();
      this.handleCloseDialog();
      this.showDialogManageClasses = true;
    },
    browseStudent(student) {
      this.$router.push({
        name: "manageStudentGroupCard",
        params: { studentId: student.id },
      });
    },
    async handleConfirmDelete() {
      if (this.editingClassId) {
        this.handleDeleteClass();
      } else {
        this.handleDeleteAccount();
      }
    },
    async handleDeleteClass() {
      await this.deleteClass(this.editingClassId);
      await this.fetchClasses();
      this.handleCloseDialog();
      this.showDialogManageClasses = true;
      this.editingClassId = "";
    },
    async handleDeleteAccount() {
      await this.deleteAccount(this.editingAccount.id);
      this.handleCloseDialog();
      this.isLoading = true;
      await this.fetchAccounts();
      this.isLoading = false;
      this.editingAccount = {};
    },
    handleShowDialogDeleteUser(user) {
      this.editingAccount = user;
      this.showDialogDelete = true;
    },
    handleShowDialogAddAdmin(isAddingSubAdmin = false) {
      this.editingAccount = {};
      this.isAddingSubAdmin = isAddingSubAdmin;
      this.showDialogAddAdmin = true;
    },
    async handleShowDialogAddStudent() {
      this.editingAccount = {};
      await this.fetchClasses();
      this.showDialogAddStudent = true;
    },
    handleEditStudent(student) {
      this.editingAccount = student;
      this.showDialogAddStudent = true;
    },
    handleEditAdmin(admin) {
      this.editingAccount = admin;
      this.showDialogAddAdmin = true;
    },
    handleShowDialogEditUser(user) {
      if (user.role == ACCOUNT_ROLE.ADMIN) {
        this.handleEditAdmin(user);
      } else {
        this.handleEditStudent(user);
      }
    },
    handleFilterClass(classIds) {
      if (classIds.length == 0) {
        this.classIds = [];
      } else {
        this.classIds = classIds;
      }
    },
    async handleShowDialogManageClasses() {
      await this.fetchClasses();
      this.showDialogManageClasses = true;
    },
    handleCloseDialog() {
      this.showDialogDelete = false;
      this.showDialogAddAdmin = false;
      this.showDialogAddStudent = false;
      this.showDialogManageClasses = false;
      this.showDialogEditClass = false;
    },
    handleEditClass(item) {
      this.editingClassId = item.id;
      this.showDialogManageClasses = false;
      this.showDialogEditClass = true;
    },
    openDeleteClassDialog(item) {
      this.editingClassId = item.id;
      this.showDialogManageClasses = false;
      this.showDialogDelete = true;
    },
  },
};
</script>

<style lang="sass" scoped>
input
  width: 100%
  padding: 16px 0 16px 16px
  border: 1px solid #D9DFEA
  border-radius: 12px
  background-color: #F9FBFC
  min-width: 305px
  &.smd
    min-width: unset
</style>
