<template>
  <v-container :style="isAdmin ? 'max-width: 100%' : 'max-width: 1150px'">
    <v-overlay
      :value="isInitialLoading"
      class="align-center justify-center"
      style="z-index: 10"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <template v-if="!isInitialLoading">
      <v-row
        class="d-flex flex-column row-container"
        :class="{
          'px-5': $vuetify.breakpoint.sm,
          'px-4': $vuetify.breakpoint.xsOnly,
          'mt-0': $vuetify.breakpoint.smAndUp,
          'mt-4': $vuetify.breakpoint.xsOnly,
        }"
      >
        <v-row
          class="title-and-search-bar"
          :style="$vuetify.breakpoint.smAndUp ? { height: '66px' } : {}"
        >
          <v-col cols="12" md="6" sm="6" xs="6" class="pa-1">
            <v-flex
              class="d-flex align-center"
              :class="{
                ['pa-4 mb-3']: $vuetify.breakpoint.smAndUp,
              }"
            >
              <img
                src="@/assets/icons/button-back.svg"
                alt=""
                @click="handleBackPage"
                style="cursor: pointer"
              />
              <v-flex
                class="ml-3"
                :class="{ 'pl-1': $vuetify.breakpoint.xsOnly }"
              >
                <h4>{{ name }}'s Browse</h4>
              </v-flex>
            </v-flex>
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="6"
            :class="{
              ['px-5']: $vuetify.breakpoint.xsOnly,
              'pr-5': $vuetify.breakpoint.sm,
              'pr-4': $vuetify.breakpoint.mdAndUp,
            }"
          >
            <div class="input-container">
              <v-text-field
                v-model.trim="searchValues"
                label="Tìm kiếm thẻ"
                append-icon="mdi-chevron-down"
                :prepend-icon="isSearch ? 'mdi-close-circle' : ''"
                append-outer-icon="mdi-magnify"
                @click:append-outer="handleSearch"
                @click:append="showPopup = !showPopup"
                @click:prepend="clearInput"
                @keyup.enter="handleSearch"
                maxlength="50"
              ></v-text-field>
              <div class="popup" v-if="showPopup">
                <v-checkbox v-model="isSearchByWord" label="Từ"></v-checkbox>
                <v-checkbox
                  v-model="isSearchByMeaning"
                  label="Nghĩa"
                ></v-checkbox>
                <v-checkbox
                  v-model="isSearchByExample"
                  label="Ví dụ"
                ></v-checkbox>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          :class="{ 'button-group-row': $vuetify.breakpoint.smAndDown }"
        >
          <v-col
            v-if="$vuetify.breakpoint.smAndUp"
            cols="12"
            :md="isAdmin ? '2' : '3'"
            sm="12"
            :class="{ 'pa-1': $vuetify.breakpoint.smAndUp }"
          ></v-col>
          <v-col
            cols="12"
            :md="isAdmin ? '10' : '9'"
            sm="12"
            style="padding-top: 0px; padding-bottom: 0px"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
                :md="isAdmin ? '8' : '6'"
                sm="6"
                class="pa-1 d-flex button-group"
              >
                <ButtonComponent
                  title="Chọn thẻ"
                  smallText
                  @click="toggleSelect"
                  :disable="isLoading"
                ></ButtonComponent>
                <ButtonComponent
                  v-if="isAdmin"
                  :disable="!selectedList.length"
                  title="Reschedule"
                  smallText
                  @click="showDialogSetReminderTime = true"
                ></ButtonComponent>
                <ButtonComponent
                  v-if="$isProStudent || isAdmin"
                  :disable="!selectedList.length"
                  title="Thay đổi <br/>thông tin thẻ"
                  smallText
                  @click="openMultipleMoveDialog"
                ></ButtonComponent>
                <ButtonComponent
                  v-if="!isAdmin"
                  @click="handleSaveOrUnSave"
                  :title="groupCardType ? 'Bỏ lưu thẻ' : 'Lưu thẻ'"
                  :disable="isLoading || !selectedList.length"
                  smallText
                >
                </ButtonComponent>
                <ButtonComponent
                  :disable="!selectedList.length"
                  title="Xóa thẻ"
                  @click="showDialogUnassignCards = true"
                  smallText
                ></ButtonComponent>
              </v-col>
              <v-col
                cols="12"
                :md="isAdmin ? '4' : '6'"
                sm="6"
                class="pa-1 pr-0 d-flex button-group"
                style="justify-content: flex-end"
              >
                <ButtonComponent
                  v-if="isFromHistory"
                  title="Quay lại tạo thẻ"
                  smallText
                  @click="$router.go(-1)"
                  :disable="isLoading"
                ></ButtonComponent>
                <ButtonComponent
                  v-if="$isProStudent && isProCard && cardDetail.id"
                  title="Cập nhật thẻ"
                  smallText
                  @click="showEditCardPopup = true"
                  :disable="isLoading || cardDetail.isCreatedByAdmin"
                ></ButtonComponent>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          :class="{ 'mt-1': $vuetify.breakpoint.xsOnly }"
          class="px-4 content-container"
        >
          <v-col cols="12" :md="isAdmin ? '2' : '3'" sm="12" class="pa-1">
            <TableComponent
              v-if="
                currentMobileTab == 'groupCards' || $vuetify.breakpoint.smAndUp
              "
              type="group-card-table"
              :items="cardGroupsData"
              :headers="cardSetHeader"
              @rowClick="handleRowClick"
              @showProCardGroups="showProCardGroups"
              @openChangeGroupCardNameDialog="openRenameGroupCardDialog"
              @openDeleteGroupCardDialog="openDeleteGroupCardDialog"
            />
            <TableComponent
              v-else-if="currentMobileTab == 'cards'"
              :items="isSearch ? filteredListCard : listCard"
              :headers="cardSetDetailHeaderMobile"
              :options="isAdmin ? optionsAdmin : optionsStudent"
              :show-select="showSelect"
              type="browse-table"
              :key="tableKey"
              :isLoading="isLoading"
              @reschedule="handleReschedule"
              @unassign="handleUnassignCard"
              @openDeleteDialog="handleUnassignCard"
              @rowClick="selectCardDetail"
              @select="(list) => (selectedList = list)"
              @saveCard="handleClickSaveCard"
              @unSaveCard="handleClickUnSaveCard"
            />
            <v-card
              v-else-if="currentMobileTab == 'cardDetail'"
              class="browse-card-detail disable-copy"
              elevation="0"
              :style="{
                height: $vuetify.breakpoint.smAndUp || 'calc(66vh - 60px)',
                minHeight: $vuetify.breakpoint.smAndUp || 'calc(66vh - 60px)',
              }"
            >
              <v-overlay
                :value="isLoading"
                class="align-center justify-center"
                style="z-index: 10"
                absolute
                opacity="0.1"
              >
                <v-progress-circular
                  color="primary"
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
              <template v-if="cardDetail.id">
                <v-tooltip
                  v-if="cardDetail.isCreatedByAdmin"
                  v-model="showTooltip"
                  bottom
                  close-delay="1000"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ps-abs tooltip"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="showTooltip = true"
                    >
                      <v-icon color="#ffeb3b">
                        mdi-shield-alert-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span
                    >Thẻ này được tạo bởi admin, không thể sửa đổi thẻ.</span
                  >
                </v-tooltip>
                <v-card-title class="mb-2">
                  <p
                    v-html="cardDetail.wordFormatted"
                    class="browse-card-title"
                  ></p>
                  &nbsp;
                  <p
                    v-if="cardDetail.wordType && cardDetail.wordType != 'null'"
                    class="browse-card-title-des"
                  >
                    ({{ cardDetail.wordType }})
                  </p>
                </v-card-title>
                <v-card-subtitle>
                  <div class="d-flex align-center mb-2">
                    <template
                      v-if="
                        cardDetail.audioLink &&
                        cardDetail.audioLink != 'null' &&
                        [1, 4].includes(cardDetail.typeCard)
                      "
                    >
                      <img
                        src="@/assets/icons/audio.svg"
                        class="cursor-pointer"
                        @click="handlePlayAudio"
                      />
                      <audio :src="cardDetail.audioLink" ref="audioPlayer" />
                    </template>
                    <h6
                      v-if="
                        cardDetail.spelling && cardDetail.spelling != 'null'
                      "
                      class="ml-1 spelling"
                    >
                      {{ cardDetail.spelling }}
                    </h6>
                  </div>
                  <p
                    v-html="formatBreakLineInEditor(cardDetail.meanFormatted)"
                    class="meaning"
                  ></p>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text class="d-flex flex-column">
                  <p class="mb-1 captionOne" style="color: #aeb7ca">Ví dụ:</p>
                  <p
                    v-html="
                      formatBreakLineInEditor(cardDetail.exampleFormatted)
                    "
                    class="example"
                  ></p>
                </v-card-text>
              </template>
              <div v-else class="d-flex-center empty-card">
                <v-icon style="color: #bac3d4">mdi-cancel</v-icon>
                <p>Trống</p>
              </div>
            </v-card>
            <transition-group name="fade">
              <FormViewAndEditCard
                v-if="$vuetify.breakpoint.smAndDown && showEditCardPopup"
                ref="formEditMobile"
                class="ps-abs bg-white mobile-form-edit"
                key="edit"
                :isProCard="isProCard"
                :folderId="selectedFolderId"
                :currentCardSetId="currentGroupCardId"
                :selectedCardId="cardDetail.id"
                @close="showDialogCancelEditCard = true"
                @updateProCard="closeAllDialogAndRefresh"
                :showSubmitBtn="false"
                :parentLoading="isLoading"
              />
            </transition-group>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.smAndUp"
            cols="12"
            :md="isAdmin ? '10' : '9'"
            sm="12"
            :class="{ 'mt-5': $vuetify.breakpoint.smAndDown }"
          >
            <v-row>
              <v-col cols="12" :md="isAdmin ? '8' : '6'" sm="6" class="pa-1">
                <TableComponent
                  :items="isSearch ? filteredListCard : listCard"
                  :headers="
                    isAdmin ? cardSetDetailHeaderAdmin : cardSetDetailHeader
                  "
                  :options="isAdmin ? optionsAdmin : optionsStudent"
                  :show-select="showSelect"
                  :key="tableKey"
                  :type="'browse-table'"
                  :isLoading="isLoading"
                  @reschedule="handleReschedule"
                  @unassign="handleUnassignCard"
                  @openDeleteDialog="handleUnassignCard"
                  @rowClick="selectCardDetail"
                  @select="(list) => (selectedList = list)"
                  @saveCard="handleClickSaveCard"
                  @unSaveCard="handleClickUnSaveCard"
                />
              </v-col>
              <v-col
                cols="12"
                :md="isAdmin ? '4' : '6'"
                sm="6"
                class="pa-1 pr-0 ps-rlt"
              >
                <v-card
                  v-if="!showEditCardPopup"
                  class="browse-card-detail disable-copy"
                  elevation="0"
                >
                  <v-overlay
                    :value="isLoading"
                    class="align-center justify-center"
                    style="z-index: 10"
                    absolute
                    opacity="0.1"
                  >
                    <v-progress-circular
                      color="primary"
                      indeterminate
                      size="64"
                    ></v-progress-circular>
                  </v-overlay>
                  <template v-if="cardDetail.id">
                    <v-tooltip
                      v-if="cardDetail.isCreatedByAdmin"
                      bottom
                      close-delay="1000"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ps-abs tooltip-pc"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="#ffeb3b">
                            mdi-shield-alert-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Thẻ này được tạo bởi admin, không thể sửa đổi
                        thẻ.</span
                      >
                    </v-tooltip>
                    <v-card-title class="mb-2">
                      <p
                        v-if="
                          cardDetail.word && cardDetail.wordFormatted != 'null'
                        "
                        v-html="cardDetail.wordFormatted"
                        class="browse-card-title"
                      ></p>
                      &nbsp;
                      <p
                        v-if="
                          cardDetail.wordType && cardDetail.wordType != 'null'
                        "
                        class="browse-card-title-des"
                      >
                        ({{ cardDetail.wordType }})
                      </p>
                    </v-card-title>
                    <v-card-subtitle>
                      <div class="d-flex align-center mb-2">
                        <template
                          v-if="
                            cardDetail.audioLink &&
                            cardDetail.audioLink != 'null' &&
                            [1, 4].includes(cardDetail.typeCard)
                          "
                        >
                          <img
                            src="@/assets/icons/audio.svg"
                            class="cursor-pointer"
                            @click="handlePlayAudio"
                          />
                          <audio
                            :src="cardDetail.audioLink"
                            ref="audioPlayer"
                          />
                        </template>
                        <h6
                          v-if="
                            cardDetail.spelling && cardDetail.spelling != 'null'
                          "
                          class="ml-1 spelling"
                        >
                          {{ cardDetail.spelling }}
                        </h6>
                      </div>
                      <p
                        v-if="
                          cardDetail.mean && cardDetail.meanFormatted != 'null'
                        "
                        v-html="
                          formatBreakLineInEditor(cardDetail.meanFormatted)
                        "
                        class="meaning"
                      ></p>
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-text class="d-flex flex-column">
                      <p class="mb-1 captionOne" style="color: #aeb7ca">
                        Ví dụ:
                      </p>
                      <p
                        v-if="
                          cardDetail.example &&
                          cardDetail.exampleFormatted != 'null'
                        "
                        v-html="cardDetail.exampleFormatted"
                        class="example"
                      ></p>
                    </v-card-text>
                  </template>
                  <div v-else class="d-flex-center empty-card">
                    <v-icon style="color: #bac3d4">mdi-cancel</v-icon>
                    <p>Trống</p>
                  </div>
                </v-card>
                <transition-group name="fade" class="ps-abs bg-white top-0">
                  <FormViewAndEditCard
                    v-if="showEditCardPopup"
                    key="edit"
                    :isProCard="isProCard"
                    :folderId="selectedFolderId"
                    :currentCardSetId="currentGroupCardId"
                    :selectedCardId="cardDetail.id"
                    @close="showDialogCancelEditCard = true"
                    @updateProCard="closeAllDialogAndRefresh"
                    :parentLoading="isLoading"
                  />
                </transition-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>

      <BrowseToolbar
        v-if="
          $vuetify.breakpoint.smAndDown && currentMobileTab !== 'groupCards'
        "
        :mobile-tab="currentMobileTab"
        :group-card-type="groupCardType"
        :is-selecting="!!selectedList"
        class="mobile-toolbar d-flex-center"
        @saveOrUnsave="handleSaveOrUnSave"
        @unassign="handleUnassignCard"
        @toggleSelect="toggleSelect"
        @preview="preview"
        @update="handleUpdateBtnMobileClick"
        @multipleMove="openMultipleMoveDialog"
        :isProCard="isProCard"
        :isCreatedByAdmin="cardDetail.isCreatedByAdmin"
      >
      </BrowseToolbar>

      <div
        v-if="showPreview"
        class="ps-abs"
        :class="$vuetify.breakpoint.smAndDown ? 'preview-mobile' : 'preview-pc'"
        v-click-outside="
          () => {
            showPreview = false;
          }
        "
      >
        <CardPreviewContent :currentCard="cardDetail" :formType="'EDIT'" />
      </div>
      <template>
        <DialogComponent
          typeDialog="set-reminder"
          :showDialogValue="showDialogSetReminderTime"
          @submit="submitReschedule"
          @closeDialog="handleCloseDialog"
        />
        <DialogComponent
          typeDialog="confirm"
          :showDialogValue="showDeleteGroupCardDialog"
          @confirmRequest="confirmDeleteProGroupCard"
          @closeDialog="handleCloseDialog"
        />
        <DialogComponent
          typeDialog="confirm"
          :showDialogValue="showDialogUnassignCards"
          @confirmRequest="submitUnassignCard"
          @closeDialog="handleCloseDialog"
        />
        <DialogComponent
          typeDialog="customRemind"
          :showDialogValue="showDialogCustomRemind"
          @closeDialog="handleCloseDialog"
        />
        <DialogComponent
          typeDialog="remind"
          :showDialogValue="showDialogRemind"
          @closeDialog="handleCloseDialog"
        />
        <DialogComponent
          v-if="showChangeGroupCardNameDialog"
          typeDialog="student-rename-card-group"
          :showDialogValue="showChangeGroupCardNameDialog"
          :initialGroupName="renamingGroupCard.groupName"
          @closeDialog="handleCancelRenameProCardGroup"
          @confirmRequest="confirmRenameProGroupCard"
        />
        <DialogComponent
          typeDialog="confirm"
          :showDialogValue="showConfirmCancelRenameCardGroup"
          description="Bạn có thay đổi chưa lưu, bạn chắc chắn muốn huỷ đổi tên bộ thẻ này chứ?"
          @closeDialog="showConfirmCancelRenameCardGroup = false"
          @confirmRequest="showConfirmCancelRenameCardGroup = false"
        />
        <DialogComponent
          typeDialog="confirm"
          description="Chắc chắn hủy bỏ sửa thẻ?"
          :showDialogValue="showDialogCancelEditCard"
          @closeDialog="showDialogCancelEditCard = false"
          @confirmRequest="handleCloseDialog"
        />
        <DialogComponent
          v-if="showWarningDialog"
          typeDialog="warning"
          description="Bạn cần chọn thẻ trước khi thực hiện hành động này."
          :showDialogValue="showWarningDialog"
          @closeDialog="showWarningDialog = false"
        />
        <DialogMultipleMoveCard
          v-if="showMultipleMoveDialog"
          :showDialogValue="showMultipleMoveDialog"
          :studentId="studentId"
          :detailCardIds="selectedList.map((i) => i.detailCardId)"
          :openingFolderId="openingFolderId"
          :openingGroupCardId="openingGroupCardId"
          @closeDialog="showMultipleMoveDialog = false"
          @confirmRequest="confirmMoveMultipleCard"
        />
      </template>
    </template>
  </v-container>
</template>
<script>
import DialogComponent from "@/components/ui/DialogComponent.vue";
import DialogMultipleMoveCard from "@/components/ui/DialogMultipleMoveCard.vue";
import TableComponent from "@/components/ui/TableComponent.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import BrowseToolbar from "@/views/browse/BrowseToolbar.vue";
import {
  convertSecondsToAcronymTime,
  formatDateyyyyMMddHHmmss,
  formatBreakLineInEditor,
} from "@/uttil";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import Format from "@/services/format";
import moment from "moment";
import { getListMenuGroupCard } from "@/uttil/menu";
import { MENU_GROUP_CARD_BROWSE_USER } from "@/constants/menu";
import { group_card_type, card_detail_type } from "@/constants/groupcard";
import {
  PRO_FOLDER_LIST,
  cardSetDetailHeader,
  cardSetDetailHeaderAdmin,
  cardSetDetailHeaderMobile,
  cardSetHeader,
} from "@/constants";
import { menu_group_card } from "@/constants/menu";
import { TABLE_HEADER_BROWSE_ADMIN } from "@/constants/menu";
import FormViewAndEditCard from "@/components/FormViewAndEditCard.vue";
import CardPreviewContent from "@/components/CardPreviewContent.vue";

export default {
  name: "BrowseView",
  components: {
    TableComponent,
    FormViewAndEditCard,
    DialogComponent,
    ButtonComponent,
    CardPreviewContent,
    BrowseToolbar,
    DialogMultipleMoveCard,
  },
  data() {
    return {
      cardGroupsData: [],
      isLoading: false,
      isInitialLoading: false,
      showPopup: false,
      isSearch: false,
      isSearchByWord: true,
      isSearchByMeaning: false,
      isSearchByExample: false,
      tableKey: 0,
      showDialogRemind: false,
      showDialogCustomRemind: false,
      searchValues: "",
      selectedCardGroupName: "",
      // -----------------card set data table------------------
      cardSetHeader: cardSetHeader,
      //   ----------card details data table----------
      cardSetDetailHeaderAdmin: cardSetDetailHeaderAdmin,
      cardSetDetailHeader: cardSetDetailHeader,
      cardSetDetailHeaderMobile: cardSetDetailHeaderMobile,
      optionsStudent: [],
      optionsAdmin:
        menu_group_card[
          (TABLE_HEADER_BROWSE_ADMIN, card_detail_type.ADMIN_BROWSE_TABLE)
        ],
      selectedList: [],
      showSelect: false,
      showDialogSetReminderTime: false,
      showDialogUnassignCards: false,
      currentGroupCardId: "",
      groupCardType: 0,
      mobileTabs: ["groupCards", "cards", "cardDetail"],
      currentMobileTab: "groupCards",
      filteredListCard: [],
      showChangeGroupCardNameDialog: false,
      showDeleteGroupCardDialog: false,
      showConfirmCancelRenameCardGroup: false,
      proFolderArrConst: PRO_FOLDER_LIST,
      renamingGroupCard: {},
      deletingGroupCard: {},
      showEditCardPopup: false,
      showDialogCancelEditCard: false,
      selectedFolderId: null,
      isProCard: false,
      showPreview: false,
      showMultipleMoveDialog: false,
      openingFolderId: "",
      openingGroupCardId: "",
      showWarningDialog: false,
      showTooltip: false,
      formatBreakLineInEditor,
    };
  },
  computed: {
    ...mapState("card", ["cardDetail"]),
    ...mapState("browse", ["cardList"]),
    ...mapGetters("list", ["getListCardGroup", "getListCard"]),
    isFromHistory() {
      return this.$route.query.detailCardId;
    },
    isAdmin() {
      return this.$route.meta.role;
    },
    studentId() {
      return this.$route.params.studentId;
    },
    name() {
      return this.getListCardGroup.fullName;
    },
    listCard() {
      let arr = this.getListCard.map((list) => {
        let reminderTime = "New";
        if (
          (list.Qn > 0 && list.reminderTime) ||
          (list.Qn == 0 && list.type == 3)
        ) {
          const toDay4h = moment
            .utc(list.reminderTime)
            .toDate()
            .setHours(4, 0, 0);
          // const nextDay4h = moment(list.reminderTime).add(1, 'days').toDate().setHours(4,0,0);
          if (moment.utc(list.reminderTime).isBefore(moment.utc(toDay4h))) {
            reminderTime = Format.FormatDate(
              moment.utc(list.reminderTime).subtract(1, "days").toDate()
            );
          } else {
            reminderTime = Format.FormatDate(list.reminderTime);
          }
          reminderTime = reminderTime.slice(0, 6) + reminderTime.slice(8);
        }
        return {
          id: list.id,
          detailCardId: list.detailCardId,
          word: list.word,
          reminderTime,
          cardGroupName: this.selectedCardGroupName,
          typeCardText: list.typeCardText,
          mean: list.mean,
          example: list.example,
          Qn: convertSecondsToAcronymTime(list.Qn),
          QnRelearn: convertSecondsToAcronymTime(list.QnRelearn),
          QnGood: convertSecondsToAcronymTime(list.QnGood),
          QnEasy: convertSecondsToAcronymTime(list.QnEasy),
          QnHard: convertSecondsToAcronymTime(list.QnHard),
          goodLevelFactor: list.goodLevelFactor?.toFixed(2),
          updatedAt: formatDateyyyyMMddHHmmss(list.updatedAt),
          selected: false, // Add the selected property for tracking selection state
        };
      });
      return arr;
    },
    isProStudent() {
      return !!this.getListCardGroup?.proFolders?.length;
    },
  },
  watch: {
    groupCardType: {
      handler() {
        this.optionsStudent = getListMenuGroupCard(
          this.groupCardType,
          MENU_GROUP_CARD_BROWSE_USER
        );
      },
      immediate: true,
    },
    "cardDetail.isProCard": {
      handler() {
        this.isProCard = this.cardDetail.isProCard;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("browse", [
      "reschedule",
      "unassignCard",
      "deleteProCard",
      "fetchAllLearnCard",
    ]),
    ...mapActions("list", [
      "fetchStudentCardGroupList",
      "fetchStudentCardDetailInAGroupCard",
      "fetchCardListInAFolder",
    ]),
    ...mapActions("card", [
      "handleSaveCard",
      "handleUnSaveCard",
      "fetchCardDetail",
    ]),
    ...mapActions("student", ["studentDeleteProGroupCard", "updateProCardSet"]),
    ...mapMutations("card", ["SET_CARD_DETAIL"]),
    ...mapMutations("list", ["SET_CARD_LIST"]),
    initCardGroupsData() {
      const cardGroups = this.getListCardGroup?.cardGroups || [];

      let newCardGroups = cardGroups.map((item) => {
        return {
          ...item,
          customGroupName: item.groupName + " (" + item.totalCards + ")",
        };
      });
      if (this.isProStudent) {
        const proFolders = this.getListCardGroup?.proFolders || [];
        let folderRows = proFolders.map((item) => {
          const showCardGroupList = this.cardGroupsData.find(
            (row) => row.isFolder && row?.folderId === item.folderId
          )?.showCardGroupList;
          return {
            ...item,
            isFolder: true,
            showCardGroupList,
            customGroupName:
              item.folderName + " (" + (item?.totalCards ?? 0) + ")",
          };
        });
        this.cardGroupsData = folderRows.concat(newCardGroups);
      } else this.cardGroupsData = newCardGroups;

      // keep folder opening after refresh
      this.proFolderArrConst.forEach((proFolderConst) => {
        const proFolderData = this.cardGroupsData.find(
          (row) => row.isFolder && row.folderId === proFolderConst.folderId
        );
        if (proFolderData?.showCardGroupList) {
          const indexOfProFolderInTable = this.cardGroupsData.findIndex(
            (row) => row.isFolder && proFolderConst.folderId === row.folderId
          );
          this.cardGroupsData.splice(
            indexOfProFolderInTable + 1,
            0,
            ...this.cardGroupsData[indexOfProFolderInTable].cardGroups.map(
              (item) => ({
                ...item,
                isProGroupCard: true,
                showMenu: false,
                actions: getListMenuGroupCard(
                  group_card_type.PRO_GROUP_CARD,
                  MENU_GROUP_CARD_BROWSE_USER
                ),
                customGroupName: item.groupName + " (" + item.totalCards + ")",
              })
            )
          );
        }
      });
    },
    showProCardGroups(groupIndex) {
      this.cardGroupsData[groupIndex].showCardGroupList =
        !this.cardGroupsData[groupIndex].showCardGroupList;

      if (this.cardGroupsData[groupIndex].showCardGroupList) {
        this.cardGroupsData.splice(
          groupIndex + 1,
          0,
          ...this.cardGroupsData[groupIndex].cardGroups.map((item) => ({
            ...item,
            isProGroupCard: true,
            showMenu: false,
            actions: getListMenuGroupCard(
              group_card_type.PRO_GROUP_CARD,
              MENU_GROUP_CARD_BROWSE_USER
            ),
            customGroupName: item.groupName + " (" + item.totalCards + ")",
          }))
        );
      } else {
        this.cardGroupsData.splice(
          groupIndex + 1,
          this.cardGroupsData[groupIndex].cardGroups.length
        );
      }
    },
    openMultipleMoveDialog() {
      if (this.selectedList.length) {
        this.showMultipleMoveDialog = !this.showMultipleMoveDialog;
      } else this.showWarningDialog = true;
    },
    preview() {
      this.showPreview = !this.showPreview;
    },
    openDeleteGroupCardDialog(proCardGroup) {
      this.showDeleteGroupCardDialog = true;
      this.deletingGroupCard = proCardGroup;
    },
    openRenameGroupCardDialog(proCardGroup) {
      this.showChangeGroupCardNameDialog = true;
      this.renamingGroupCard = proCardGroup;
    },
    handleCancelRenameProCardGroup(mustConfirm) {
      mustConfirm
        ? (this.showConfirmCancelRenameCardGroup = true)
        : this.handleCloseDialog();
    },
    async confirmRenameProGroupCard(proGroupCard) {
      await this.updateProCardSet({
        cardSetId: this.renamingGroupCard.groupCardId,
        cardSetName: proGroupCard.groupName,
        folderId: proGroupCard.folderId,
      });
      this.renamingGroupCard = null;
      this.closeAllDialogAndRefresh();
    },
    async confirmDeleteProGroupCard() {
      await this.studentDeleteProGroupCard(this.deletingGroupCard.groupCardId);
      this.deletingGroupCard = "";
      this.closeAllDialogAndRefresh();
    },
    async confirmMoveMultipleCard() {
      this.selectedList = [];
      this.closeAllDialogAndRefresh();
    },
    async fetchCardDetailList() {
      if (this.currentGroupCardId)
        return await this.fetchStudentCardDetailInAGroupCard(
          this.currentGroupCardId
        );
      else
        return await this.fetchCardListInAFolder({
          folderId: this.selectedFolderId,
          studentId: this.studentId,
        });
    },
    async closeAllDialogAndRefresh() {
      this.isLoading = true;
      this.handleCloseDialog();
      await this.fetchStudentCardGroupList({ studentId: this.studentId });
      await this.fetchCardDetailList();
      if (this.cardDetail.id) {
        await this.fetchCardDetail(this.cardDetail.id);
      }
      this.initCardGroupsData();
      this.isLoading = false;
    },
    async handleSearch() {
      this.isLoading = true;
      if (this.searchValues !== "") {
        this.isSearch = true;
        this.currentMobileTab = "cards";
        await this.fetchAllLearnCard({
          isSearchByWord: this.isSearchByWord,
          isSearchByMeaning: this.isSearchByMeaning,
          isSearchByExample: this.isSearchByExample,
          studentId: this.studentId,
          search: this.searchValues,
        });
        let arr = this.cardList.map((item) => {
          let reminderTime = "New";
          if (
            (item.Qn > 0 && item.reminderTime) ||
            (item.Qn == 0 && item.type == 3)
          ) {
            const toDay4h = moment
              .utc(item.reminderTime)
              .toDate()
              .setHours(4, 0, 0);
            // const nextDay4h = moment(item.reminderTime).add(1, 'days').toDate().setHours(4,0,0);
            if (moment.utc(item.reminderTime).isBefore(moment.utc(toDay4h))) {
              reminderTime = Format.FormatDate(
                moment.utc(item.reminderTime).subtract(1, "days").toDate()
              );
            } else {
              reminderTime = Format.FormatDate(item.reminderTime);
            }
          }
          return {
            id: item.id,
            detailCardId: item.detailCardId,
            word: item.word,
            reminderTime,
            cardGroupName: item.groupCardName,
            typeCardText: item.typeCardText,
            mean: item.mean,
            example: item.example,
            selected: false, // Add the selected property for tracking selection state
          };
        });
        this.filteredListCard = arr;
      } else {
        this.filteredListCard = this.listCard;
      }
      this.isLoading = false;
    },
    clearInput() {
      this.isSearch = false;
      this.searchValues = "";
      this.currentMobileTab = "groupCards";
      this.filteredListCard = this.listCard;
    },
    handlePlayAudio() {
      const audioPlayer = this.$refs.audioPlayer;
      audioPlayer.currentTime = 0;
      audioPlayer.play();
    },
    toggleSelect() {
      if (this.showSelect) {
        this.selectedList = [];
      }
      this.showSelect = !this.showSelect;
    },
    async submitReschedule(reminder) {
      this.isLoading = true;
      let reminderTime = reminder.reminderTime;
      let Qn = reminder.Qn;
      await this.reschedule({
        studentId: this.studentId,
        body: {
          reminderTime,
          Qn,
          learnCardIds: this.selectedList.map((i) => i.id),
        },
      });
      this.handleCloseDialog();
      this.tableKey++; // Increment the tableKey to force a re-render
      this.selectedList = [];
      this.showSelect = false;
      await this.fetchCardDetailList();
      this.isLoading = false;
    },
    async submitUnassignCard() {
      this.isLoading = true;
      if (this.selectedList.length === 0) return;
      await this.unassignCard(this.selectedList.map((item) => item.id));
      // await this.deleteProCard(this.selectedList.map((item) => item.id));
      if (this.isSearch) {
        await this.handleSearch();
      } else {
        await this.fetchCardDetailList();
      }
      this.handleCloseDialog();
      if (this.currentMobileTab === "cardDetail") {
        this.currentMobileTab = "cards";
      }
      this.selectedList = [];
      await this.fetchStudentCardGroupList({ studentId: this.studentId });
      this.isLoading = false;
    },
    handleReschedule(item) {
      this.showDialogSetReminderTime = true;
      this.selectedList = [item];
    },
    handleUnassignCard(item) {
      if (!this.selectedList.length && !item) {
        this.showWarningDialog = true;
        return;
      }
      this.showDialogUnassignCards = true;
      this.selectedList = item ? [item] : this.selectedList;
    },
    async handleRowClick(groupCard) {
      if (this.isLoading) {
        return;
      }
      this.SET_CARD_DETAIL({});
      this.isLoading = true;
      if (this.$vuetify.breakpoint.smAndDown) {
        this.currentMobileTab = "cards";
      }
      this.isProCard = groupCard.isProGroupCard || groupCard.isFolder;
      if (this.isProCard && groupCard.isFolder) {
        this.selectedFolderId = groupCard.folderId;
        this.currentGroupCardId = null;
        this.selectedCardGroupName = groupCard.folderName;
        this.openingFolderId = groupCard.folderId;
        this.openingGroupCardId = "";
      } else {
        this.openingFolderId = groupCard.folderId;
        this.openingGroupCardId = groupCard.id;
        this.currentGroupCardId = groupCard.id;
        this.selectedFolderId = null;
        this.selectedCardGroupName = groupCard.groupName;
      }
      this.SET_CARD_LIST([]);
      const mess = await this.fetchCardDetailList();
      if (!mess) {
        this.groupCardType = groupCard.type ?? 0;
      }
      this.showSelect = false;
      this.selectedList = [];
      this.isLoading = false;
    },
    backPageRouter() {
      if (this.isAdmin) {
        this.$router.replace(`/manage-student-group-card/${this.studentId}`);
      } else this.$router.replace(`/group-card/${this.studentId}`);
    },
    handleBackPage() {
      if (
        this.$vuetify.breakpoint.smAndUp ||
        this.currentMobileTab == "groupCards"
      ) {
        this.backPageRouter();
      } else if (this.currentMobileTab == "cards") {
        this.currentMobileTab = "groupCards";
      } else if (this.currentMobileTab == "cardDetail") {
        this.currentMobileTab = "cards";
      }
    },
    async selectCardDetail(card) {
      this.showEditCardPopup = false;
      if (this.$vuetify.breakpoint.smAndDown) {
        this.currentMobileTab = "cardDetail";
      }
      await this.fetchCardDetail(card.detailCardId);
    },
    handleCloseDialog() {
      this.showDialogCustomRemind = false;
      this.showDialogRemind = false;
      this.showDialogSetReminderTime = false;
      this.showDialogUnassignCards = false;
      this.showDeleteGroupCardDialog = false;
      this.showChangeGroupCardNameDialog = false;
      this.showConfirmCancelRenameCardGroup = false;
      this.showDialogCancelEditCard = false;
      this.showEditCardPopup = false;
      this.showMultipleMoveDialog = false;
    },
    async handleSaveOrUnSave() {
      if (!this.selectedList.length) {
        this.showWarningDialog = true;
        return;
      }
      this.isLoading = true;
      let mess;
      const learnCardIdList = this.selectedList.map((item) => item.id);
      const payload = {
        learnCardIdList,
      };
      if (this.groupCardType) {
        mess = await this.handleUnSaveCard(payload);
      } else {
        payload.studentId = this.studentId;
        mess = await this.handleSaveCard(payload);
      }
      if (!mess) {
        this.selectedList = [];
        this.tableKey++;
        this.fetchStudentCardGroupList({ studentId: this.studentId });
        await this.fetchCardDetailList();
      }
      this.isLoading = false;
    },
    async handleClickSaveCard(card) {
      if (this.isLoading) return;
      this.isLoading = true;
      const payload = {
        studentId: this.studentId,
        learnCardIdList: [card.id],
      };
      await this.handleSaveCard(payload);
      this.fetchStudentCardGroupList({ studentId: this.studentId });
      await this.fetchCardDetailList();
      this.isLoading = false;
    },
    async handleClickUnSaveCard(card) {
      if (this.isLoading) return;
      this.isLoading = true;
      const payload = {
        learnCardIdList: [card.id],
      };
      this.handleUnSaveCard(payload);
      this.fetchStudentCardGroupList({ studentId: this.studentId });
      await this.fetchCardDetailList();
      this.isLoading = false;
    },
    async handleUpdateBtnMobileClick() {
      this.showEditCardPopup
        ? await this.$refs.formEditMobile.handleUpdateCard()
        : (this.showEditCardPopup = true);
    },
  },
  async created() {
    this.isInitialLoading = true;
    if (this.$route.query.detailCardId) {
      this.searchValues = this.$route.query.word;
      this.isProCard = true;
      this.selectCardDetail({ detailCardId: this.$route.query.detailCardId });
      this.showEditCardPopup = true;
      this.handleSearch();
    }
    // else {
    //   this.SET_CARD_DETAIL({});
    // }
    await this.fetchStudentCardGroupList({ studentId: this.studentId });
    this.initCardGroupsData();
    this.isInitialLoading = false;
  },
};
</script>
<style lang="scss">
.browse-card-title p {
  margin-bottom: 0px !important;
  font-weight: 700;
  font-size: 28px;
}
.bg-white {
  background-color: white;
}
.top-0 {
  top: 0px;
}
</style>

<style lang="scss" scoped>
.tooltip {
  top: 10px;
  right: 10px;
}
.tooltip-pc {
  top: -5px;
  right: 0px;
}
.preview-pc {
  z-index: 100;
  bottom: 65px;
  left: 50%;
  transform: translateX(-50%);
}
.preview-mobile {
  z-index: 100;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
}
.mobile-form-edit {
  top: 70px;
  width: 100vw;
  left: 0px;
  height: calc(100vh - 76px);
  ::v-deep .form-input-wrap {
    max-height: calc(100vh - 130px);
  }
}
.mobile-toolbar {
  width: 100%;
  justify-content: space-around;
  position: fixed;
  bottom: 0px;
  margin: 0px -12px;
  gap: 0;
  height: 45px;
  border-top: 1px solid #e9edf5;
  z-index: 2;
  background-color: #fff;
}
.button-group-row {
  margin-top: 0px !important;
  .button-group {
    justify-content: space-around;
  }
}
.empty-card {
  flex-direction: column;
  color: #bac3d4;
  margin-top: 50%;
}
p {
  margin-bottom: 0px !important;
}
.spelling,
.meaning {
  font-size: 16px;
  color: #384961;
}
.example {
  font-weight: 400;
  color: #384961;
  font-size: 16px;
}
.input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.popup {
  position: absolute;
  top: 50px;
  right: 10px;
  width: 140px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  z-index: 20000;
  border-radius: 12px;
}
</style>

<style lang="sass" scoped>
.input-search
  width: 100%
  padding: 0px 10px
  border: 1px solid #D9DFEA
  border-radius: 12px
  background-color: #F9FBFC
  min-width: 305px
  &.smd
    min-width: unset
input
  width: 100%
  padding: 16px 0 16px 16px
  border: 1px solid #D9DFEA
  border-radius: 12px
  background-color: #F9FBFC
  min-width: 305px
  &.smd
    min-width: unset
.button-group
  gap: 5px
.browse-card-detail
  background-color: #F9FBFC
  border-radius: 12px
  border: 1px solid #E9EDF5
  min-height: 66vh
.browse-card-title
  color: #1BB763
.browse-card-title-des
  margin-left: 3px
  color: #F56742
  font-weight: 600
  font-size: 16px
li
  font-weight: bold
</style>
