<template>
  <div>
    <v-data-table
      :height="$vuetify.breakpoint.smAndUp ? '66vh' : 'calc(66vh - 20px)'"
      :items="items"
      :hide-default-footer="true"
      disable-pagination
      mobile-breakpoint="0"
      :loading="isLoading"
      :show-select="showSelect"
      return-object
      v-model="selectedList"
      :item-value="keyValue"
      class="table-cus"
      @click:row="(item) => handleNavigate(item)"
    >
      <thead slot="header">
        <tr class="v-data-table-header">
          <th
            v-for="header in accountsHeader"
            :key="header.text"
            :class="header.className"
          >
            <div class="text-center">
              <span>{{ header.text }}</span>
              <template v-if="header.isFilter">
                <v-menu
                  :close-on-content-click="false"
                  content-class="filter-classes"
                  location="start"
                  offset-y
                  transition="scale"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="btn-open-menu"
                      :disabled="classes.length === 0 || loading"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                  <v-list v-if="classes.length">
                    <v-list-item
                      v-for="(option, index) in classes"
                      :key="option.id"
                      :value="index"
                      :disabled="loading"
                    >
                      <v-list-item-title>
                        <div>
                          <v-checkbox
                            v-model="listCheckBox"
                            :label="option.className"
                            :value="option.id"
                            @change="checkboxUpdated"
                            checked
                          >
                          </v-checkbox>
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </div>
          </th>
        </tr>
      </thead>
      <tbody slot="body">
        <template>
          <tr
            v-for="item in items"
            :key="item.id + item.userName"
            @click="handleNavigate(item)"
          >
            <td
              v-for="header in accountsHeader"
              :key="header.text"
              class="text-center pt-2 pb-2 ps-rlt"
            >
              <template v-if="header.value === 'fullName'">
                {{ item[header.value] }}
                <span
                  v-if="item.status === STUDENT_PRO_STATUS_LIST.PRO"
                  class="pro-badge ps-abs ml-3"
                  >PRO</span
                >
                <span
                  v-else-if="
                    item.status === STUDENT_PRO_STATUS_LIST.USED_TO_PRO
                  "
                  class="used-to pro-badge ps-abs ml-3"
                  >TỪNG PRO</span
                >
              </template>
              <template v-else-if="header.value != 'actions'">
                {{ item[header.value] }}
              </template>
              <template v-else>
                <template v-if="$route.name !== 'manageStudentGroupCard'">
                  <v-menu
                    offset-y
                    transition="slide-y-transition"
                    location="end"
                  >
                    <!-- -------------------Menu list-------------------- -->
                    <template v-slot:activator="{ attrs, on }">
                      <img
                        src="@/assets/icons/menu.svg"
                        class="hover-scale"
                        style="cursor: pointer; padding: 15px 2px"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>

                    <v-list class="pa-0 layout-list" style="min-width: 180px">
                      <v-list-item
                        v-for="(option, index) in accountsOptions"
                        class="px-5 py-1"
                        :key="index"
                        link
                        @click="handleEmit(option.emitFunction, item)"
                      >
                        <v-img :src="option.icon && getIcon(option.icon)" />
                        <v-list-item-title class="ml-4 d-flex">
                          {{ option.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <img
                  v-else
                  src="@/assets/icons/trash.svg"
                  class="hover-scale"
                  style="cursor: pointer; padding: 15px 2px"
                  @click.stop="deleteItem(item)"
                />
              </template>
            </td>
          </tr>
        </template>
      </tbody>
    </v-data-table>
  </div>
</template>

<script>
import {
  STUDENT_PRO_STATUS_LIST,
  ACCOUNT_ROLE,
  accountsHeader,
  accountsOptions,
} from "@/constants/index";

export default {
  data() {
    return {
      selectedList: [],
      page: 1,
      loading: false,
      listCheckBox: [],
      tableWrapper: {},
      scrollTop: 0,
      STUDENT_PRO_STATUS_LIST,
      ACCOUNT_ROLE,
      accountsHeader,
      accountsOptions,
    };
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
    items: {
      type: Array,
      default: () => [],
    },
    cols: {
      type: Number,
      default: null,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    keyValue: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.tableWrapper = this.$el.children[0].children[0];
  },
  activated() {
    this.tableWrapper.scrollTop = this.scrollTop;
  },
  watch: {
    selectedList: {
      handler() {
        this.handleSelect();
      },
      deep: true,
    },
  },
  methods: {
    getIcon(name) {
      return require("@/assets/icons/" + name + ".svg");
    },
    handleEmit(action, item) {
      return this.$emit(action, item);
    },
    handleNavigate(item) {
      this.scrollTop = this.tableWrapper.scrollTop;
      this.$emit("navigate", item);
    },
    handleSelect() {
      this.$emit("select", this.selectedList);
    },
    deleteItem(item) {
      this.$emit("delete", item);
    },
    checkboxUpdated() {
      this.$emit("filterClass", this.listCheckBox);
    },
  },
};
</script>

<style lang="scss" scoped>
.pro-badge {
  color: red;
  display: inline-block;
  top: 5px;
  &.used-to {
    color: rgb(98, 0, 234);
  }
}
</style>

<style lang="scss">
.hover-scale {
  width: 20px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
}

.v-data-table__wrapper {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #bdb8b8;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #726f6f;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.table-cus {
  .v-data-table-header {
    th {
      position: relative;
      background-color: #453fe3 !important;
    }
    .btn-open-menu {
      color: white;
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
      &:disabled {
        color: #f8f8f8;
        cursor: not-allowed;
      }
    }
    .colum-fullName {
      width: 200px;
    }
    .colum-date {
      min-width: 100px;
    }
    .colum-class {
      width: 200px;
      min-width: 200px;
    }
  }
  tbody {
    tr {
      td {
        font-weight: 600;
        &:nth-child(2) {
          color: #3887fe;
        }
        &:nth-child(3) {
          color: #1bb763;
        }
      }
    }
  }
}
.filter-classes {
  .v-list-item {
    &:hover {
      background-color: #f6f6f6;
    }
  }
  .v-messages {
    display: none;
  }
  .v-input--selection-controls {
    margin-top: 0;
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: 1200px) {
  .v-data-table__wrapper {
    .v-data-table-header {
      .colum-class {
        width: 20%;
      }
      .colum-fullName {
        width: 15%;
      }
    }
  }
}
</style>

<style lang="sass" scoped>
.v-data-table
  border: 1px solid rgba(0, 0, 0, 0.12)
  border-top-left-radius: 12px
  border-top-right-radius: 12px
::v-deep .v-data-table__wrapper
  .v-data-table-header
    background-color: #453FE3 !important
    th
      border-right: 1px solid
      color: #FFFFFF !important
      &:first-child
        border-top-left-radius: 12px
      &:last-child
        border-top-right-radius: 12px
        border-right: none
  tbody
    tr
      td
        font-weight: 600
        &:nth-child(2)
          color: #3887FE
        &:nth-child(3)
          color: #1BB763
</style>
