var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('LayoutCard',{attrs:{"title":_vm.getTitle,"subtitle":"Bộ thẻ:","type":"CÂU HỎI","timeLeft":_vm.estimateTime,"disableActions":_vm.isProcessing,"showLoading":_vm.showLoading},on:{"openDeleteDialog":_vm.handleOpenDeleteDialog,"back":_vm.handleBackPage,"pressEnter":_vm.handleAnswer,"backCard":_vm.handleBackCard,"saveCard":_vm.handleClickSaveCard,"unSaveCard":_vm.handleClickUnSaveCard,"rightSingleTap":_vm.handleAnswer,"leftDoubleTap":_vm.handleBackCard,"rightDoubleTap":_vm.handlePlayAudio}},[(_vm.showReload)?_c('div',{staticClass:"d-flex justify-content-center align-items-center",style:({
        minHeight: _vm.$vuetify.breakpoint.xsOnly
          ? 'calc(100vh - 330px)'
          : '375px',
      })},[_c('ButtonComponent',{attrs:{"title":"Tải lại","append-icon":"reload","classBtn":"submit-answer","smallText":""},on:{"click":_vm.handleReload}})],1):(_vm.isLoadingNextCard || _vm.showLoading)?_c('div',{staticClass:"d-flex justify-content-center align-items-center",style:({
        minHeight: _vm.$vuetify.breakpoint.xsOnly
          ? 'calc(100vh - 330px)'
          : '375px',
      })},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1):_c('v-layout',{staticClass:"d-flex flex-column disable-copy",style:({
        minHeight: _vm.$vuetify.breakpoint.xsOnly
          ? 'calc(100vh - 330px)'
          : 'unset',
      })},[_c('div',{staticClass:"mb-2 justify-center d-flex",class:{
          'mb-4': _vm.$vuetify.breakpoint.xsOnly,
        }},[_c('div',{staticClass:"col justify-center"},[(
              _vm.currentCard.typeCard == 1 && this.currentCard.audioLink !== null
            )?_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/icons/audio.svg")},on:{"click":_vm.handlePlayAudio}}),_c('audio',{ref:"audioPlayer",attrs:{"src":_vm.currentCard.audioLink,"type":"audio/*","preload":"auto","autoplay":"","muted":""}})]):([4, 5].includes(_vm.currentCard.typeCard))?_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"word",class:{
                fw400: _vm.currentCard.typeCard == 5,
                fw600: _vm.currentCard.typeCard == 4,
                mobile: _vm.$vuetify.breakpoint.xsOnly,
              },domProps:{"innerHTML":_vm._s(
                _vm.currentCard.typeCard == 4
                  ? _vm.currentCard.mean
                  : _vm.formatBreakLineInEditor(_vm.currentCard.meanFormatted)
              )}})]):([2, 3].includes(_vm.currentCard.typeCard))?_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"word fw400",class:{
                mobile: _vm.$vuetify.breakpoint.xsOnly,
              },domProps:{"innerHTML":_vm._s(_vm.currentCard.wordFormatted)}})]):_vm._e(),(
              [1, 4].includes(_vm.currentCard.typeCard) &&
              _vm.currentCard.wordType != 'null'
            )?_c('div',[_c('p',{staticClass:"text-center word-type",class:{
                mobile: _vm.$vuetify.breakpoint.xsOnly,
              },staticStyle:{"color":"#f56742"}},[_vm._v(" ("+_vm._s(_vm.currentCard.wordType)+") ")])]):_vm._e()])]),_c('div',[_c('div',{staticClass:"mb-2"},[_c('label',{attrs:{"for":"answer"}},[_c('h6',[_vm._v("Câu trả lời:")])])]),_c('InputComponent',{attrs:{"inputProps":_vm.answerInput,"autoFocus":_vm.currentCard.typeCard == 4 && _vm.$vuetify.breakpoint.smAndUp},on:{"blur":function($event){_vm.isFocus = false},"focus":function($event){_vm.isFocus = true}},model:{value:(_vm.answerValue),callback:function ($$v) {_vm.answerValue=$$v},expression:"answerValue"}})],1),_c('div',{staticClass:"d-flex align-center justify-space-between mt-4"},[_c('div',{staticClass:"d-flex",class:{
            'flex-column': _vm.$vuetify.breakpoint.xsOnly,
          }},[_c('p',[_vm._v(" Số thẻ mới:"),_c('span',{staticClass:"ml-2 text-1"},[_vm._v(_vm._s(_vm.numOfNewCardsWhenStartingLearn - _vm.numOfNewCardsLearning >= 0 ? _vm.numOfNewCardsWhenStartingLearn - _vm.numOfNewCardsLearning : 0))])]),_c('p',{class:{ 'ml-8': _vm.$vuetify.breakpoint.smAndUp }},[_vm._v(" Số thẻ cần ôn:"),_c('span',{staticClass:"ml-2 text-2"},[_vm._v(_vm._s(_vm.numOfRVSCardsWhenStartingLearn - _vm.numOfRVSCardsLearning >= 0 ? _vm.numOfRVSCardsWhenStartingLearn - _vm.numOfRVSCardsLearning : 0))])])]),(
            !_vm.showLoading &&
            _vm.currentGroupCard?.countDown?.show &&
            _vm.currentGroupCard?.countDown?.time
          )?_c('CountdownTimer',{attrs:{"time":_vm.countdownTime}}):_vm._e(),_c('ButtonComponent',{attrs:{"title":"Đáp án","append-icon":"arrow-right","classBtn":"submit-answer","disable":!_vm.currentCard?.id,"smallText":""},on:{"click":_vm.handleAnswer}})],1)]),_c('DialogComponent',{attrs:{"showDialogValue":_vm.showDialogDelete,"typeDialog":"delete"},on:{"closeDialog":function($event){_vm.showDialogDelete = false},"confirmRequest":_vm.handleConfirmDeleteCard}}),_c('DialogComponent',{attrs:{"showDialogValue":_vm.showSuccessDiaLog,"typeDialog":"success"},on:{"closeDialog":_vm.handleCloseDialogSuccess}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }