<template>
  <v-row
    v-if="!getIsGroupCardClone"
    class="d-flex align-center footer-wrapper"
    :class="$vuetify.breakpoint.xsOnly ? 'flex-nowrap' : ''"
  >
    <!--    btn học lại-->
    <v-col
      md="3"
      sm="2"
      xs="2"
      class="d-flex flex-column"
      :class="$vuetify.breakpoint.xsOnly ? 'relearn-mobile' : ''"
    >
      <ButtonComponent
        @click="handleClickRelearn"
        title="Học lại"
        prepend-icon="reload"
        isGrayBtn
        classBtn="relearn-btn"
        :disable="isGetLearnCard"
      />
      <p class="captionOne text-center relearnQn">{{ QnRelearn }}</p>
    </v-col>
    <!--    chọn option khó dễ -->
    <v-col
      md="9"
      sm="10"
      xs="10"
      class="d-flex"
      :class="
        $vuetify.breakpoint.xsOnly ? 'difficult-button' : 'justify-center'
      "
    >
      <v-col
        cols="12"
        md="4"
        sm="4"
        xs="4"
        class="d-flex flex-column align-center px-1"
        :class="$vuetify.breakpoint.xsOnly ? 'rate-button' : ''"
        ><EvaluationButtonComponent
          title="Khó"
          prependIcon="hard"
          typeButton="hardType"
          :disable="isGetLearnCard"
        />
        <p class="mt-1 captionOne">{{ QnHard }}</p>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="4"
        :class="$vuetify.breakpoint.xsOnly ? 'rate-button' : ''"
        class="d-flex flex-column align-center px-1"
        ><EvaluationButtonComponent
          title="Tốt"
          prependIcon="normal"
          typeButton="normalType"
          :disable="isGetLearnCard"
        />
        <p class="mt-1 captionOne">{{ QnGood }}</p>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="4"
        xs="3"
        class="d-flex flex-column align-center px-1"
        :class="$vuetify.breakpoint.xsOnly ? 'rate-button' : ''"
      >
        <EvaluationButtonComponent
          title="Dễ"
          prependIcon="easy"
          typeButton="easyType"
          :disable="isGetLearnCard"
        />
        <p class="mt-1 captionOne">{{ QnEasy }}</p>
      </v-col>
    </v-col>
  </v-row>
  <v-row
    v-else
    class="d-flex flex-nowrap mt-6"
    :class="$vuetify.breakpoint.xsOnly ? 'justify-start ' : 'justify-center'"
  >
    <!--    btn học lại-->
    <v-col cols="auto" class="d-flex flex-column">
      <ButtonComponent
        @click="handleClickRelearnCloned"
        title="Học lại"
        prepend-icon="reload"
        isGrayBtn
        classBtn="relearn-btn"
        :disable="isGetLearnCard"
      />
      <p class="captionOne text-center relearnQn">{{ QnRelearn }}</p>
    </v-col>
    <!--    btn học lại-->
    <v-col sm="4" class="d-flex flex-column">
      <ButtonComponentCus
        title="Tốt"
        prependIcon="normal"
        outlined
        class="btn-normal"
        @click="handleClickDelete"
        :disabled="isGetLearnCard"
      />
    </v-col>
  </v-row>
</template>
<script>
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import EvaluationButtonComponent from "@/components/ui/EvaluationButtonComponent.vue";
import { convertSecondsToAcronymTime } from "@/uttil";
import { mapGetters, mapState } from "vuex";
import ButtonComponentCus from "@/components/ui/ButtonComponentCus.vue";
import { getLocalStore } from "@/uttil/localStorage";
import {
  GROUP_CARD_TYPE_STORE_NAME,
  group_card_type,
} from "@/constants/groupcard";

export default {
  components: {
    EvaluationButtonComponent,
    ButtonComponent,
    ButtonComponentCus,
  },
  props: {
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isGroupCardClone: false,
    };
  },
  methods: {
    handleClickRelearn() {
      this.$emit("clickRelearn");
    },
    handleClickDelete() {
      this.$emit("clickDelete");
    },
    handleClickRelearnCloned() {
      this.$emit("clickCloneType");
    },
  },
  computed: {
    ...mapGetters("card", ["getCurrentCard"]),
    ...mapState("card", ["isGetLearnCard"]),
    QnHard() {
      let time = this.getCurrentCard.QnHard;
      return convertSecondsToAcronymTime(time, false);
    },
    QnGood() {
      let time = this.getCurrentCard.QnGood;
      return convertSecondsToAcronymTime(time, false);
    },
    QnEasy() {
      let time = this.getCurrentCard.QnEasy;
      return convertSecondsToAcronymTime(time, false);
    },
    QnRelearn() {
      let time = this.getCurrentCard.QnRelearn;
      return convertSecondsToAcronymTime(time, false);
    },
    getIsGroupCardClone() {
      return this.isGroupCardClone;
    },
  },
  mounted() {
    const groupCardType = getLocalStore(GROUP_CARD_TYPE_STORE_NAME);
    const isClone =
      groupCardType &&
      groupCardType == group_card_type.GROUP_CARD_CLONED_FROM_SAVED;
    this.isGroupCardClone = isClone;
  },
};
</script>

<style lang="scss" scoped>
.footer-wrapper {
  max-width: 100%;
  margin: 0px 5px;
  .relearn-mobile {
    max-width: 111px;
    padding: 0px;
  }
  .difficult-button {
    width: calc(100% - 111px);
    padding: 0px;
    justify-content: flex-end;
    gap: 5px;
    .rate-button {
      max-width: 30%;
    }
  }
}
.relearnQn {
  width: 111px;
  &.disable {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.btn-normal {
  border: 1px solid #3887fe;
  color: #3887fe;
  width: 100%;
  &.v-btn:disabled {
    color: rgba(56, 135, 254, 0.5);
  }
}

.v-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>

<style lang="sass" scoped>
.captionOne
  margin-top: 4px
  white-space: nowrap
</style>
