<template>
  <div class="container">
    <div
      class="d-flex folder-table-header"
      @click="showDropdownCardSetList = !showDropdownCardSetList"
    >
      <div
        v-text="currentCardSetName === '' ? 'Chọn bộ thẻ' : currentCardSetName"
      ></div>
      <img :src="getImgUrl('icons/arrow-down.svg')" />
    </div>
    <v-overlay
      v-model="loading"
      class="align-center justify-center"
      style="z-index: 10"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <transition name="fade">
      <div v-if="showDropdownCardSetList" class="folder-table-list">
        <div class="folder-table-row">
          <input
            type="text"
            placeholder="Nhập tên bộ thẻ"
            class="add-new-folder"
            v-model="newCardSetName"
            @keyup.enter="addCardSet"
          />
          <img
            v-if="newCardSetName != ''"
            class="cursor-pointer"
            :src="getImgUrl('icons/trash.svg')"
            @click="newCardSetName = ''"
          />
        </div>
        <div
          v-for="set in cardSets"
          :key="set.id"
          :title="set.groupName"
          class="folder-table-row cursor-pointer"
          @click.self="chooseCardSet(set.id, set.groupName)"
        >
          <p
            v-if="!set.isEditing"
            class="folder-name text-overflow"
            @dblclick="toggleRenameCardSet(set.id)"
          >
            {{ set.groupName + " (" + set.numberOfCards + ")" }}
          </p>
          <input
            v-else
            id="rename-card-set"
            type="text"
            class="add-new-folder"
            @keyup.enter="updateCardSetName(set.id, set.groupName)"
            @blur="quitRenameCardSet(set.id)"
            v-model="set.groupName"
          />
          <div class="button-group d-flex">
            <p
              v-if="set.isEditing"
              title="Hủy đổi tên"
              class="cursor-pointer cancel-rename"
              @click="quitRenameCardSet(set.id)"
            >
              X
            </p>
            <v-icon
              title="Di chuyển bộ thẻ"
              style="color: blue"
              @click="moveCartSet(set)"
              >mdi-folder-move-outline</v-icon
            >
            <v-icon title="Duplicate bộ thẻ" @click="duplicate(set)"
              >mdi-content-copy</v-icon
            >
            <v-icon
              title="Nạp bộ thẻ"
              style="color: green"
              @click="openAssignDialog(set)"
              >mdi-ballot-outline</v-icon
            >
            <img
              class="cursor-pointer"
              title="Xóa bộ thẻ"
              :src="getImgUrl('icons/trash.svg')"
              @click="onDeleteCardSetDialog(set.id)"
            />
          </div>
        </div>
      </div>
    </transition>
    <DialogComponent
      typeDialog="confirm"
      description="Bạn chắc chắn muốn xóa bộ thẻ?"
      :showDialogValue="isShowDialogDelete"
      @closeDialog="isShowDialogDelete = false"
      @confirmRequest="onDeleteCardSet"
    />
    <DialogAssignCard
      :showDialogValue="isShowDialogAssign"
      :group-card-id="assigningGroupCard"
      @closeDialog="isShowDialogAssign = false"
      :showSelect="true"
      :totalCards="assignMaxCards"
    />
    <DialogMoveCard
      v-if="isShowDialogMove"
      :showDialogValue="isShowDialogMove"
      :group-card-id="movingGroupCardId"
      @refresh="$emit('refresh')"
      @closeDialog="isShowDialogMove = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DialogComponent from "@/components/ui/DialogComponent.vue";
import DialogAssignCard from "@/components/ui/DialogAssignCard.vue";
import DialogMoveCard from "@/components/ui/DialogMoveCard.vue";

export default {
  components: {
    DialogComponent,
    DialogAssignCard,
    DialogMoveCard,
  },
  data() {
    return {
      showDropdownCardSetList: true,
      currentCardSetName: "",
      cardSets: [],
      newCardSetName: "",
      isShowDialogDelete: false,
      isShowDialogAssign: false,
      deletingCardSetId: "",
      assigningGroupCard: "",
      assignMaxCards: 0,
      movingGroupCardId: "",
      isShowDialogMove: false,
      loading: false,
    };
  },
  props: {
    folderId: {
      type: [String, Number],
    },
  },
  computed: {
    ...mapGetters("admin", ["getCardSets"]),
  },
  created() {},
  watch: {
    folderId: {
      handler() {
        this.toggleCardSets();
        this.currentCardSetName = "Chọn bộ thẻ";
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("admin", [
      "createNewCardSet",
      "duplicateCardSet",
      "updateCardSet",
      "deleteCardSet",
      "fetchCardSets",
      "fetchUnassignedClasses",
      "fetchFolderList",
    ]),
    ...mapActions("card", ["fetchAllCards"]),
    async updateCardSetName(id, newCardSetName) {
      if (newCardSetName != "") {
        this.loading = true;
        await this.updateCardSet({
          cardSetId: id,
          cardSetName: newCardSetName,
          folderId: this.folderId,
        });
        await this.fetchCardSets(this.folderId);
        this.loading = false;
        document.getElementById("rename-card-set").blur();
      }
    },
    toggleRenameCardSet(setId) {
      this.$set(
        this.cardSets.find((set) => set.id == setId),
        "isEditing",
        true
      );
      this.$nextTick(() => {
        document.getElementById("rename-card-set").focus();
      });
    },
    quitRenameCardSet(setId) {
      const currentSet = this.cardSets.find((set) => set.id == setId);
      currentSet.isEditing = false;
      currentSet.groupName = this.getCardSets.find(
        (set) => set.id == setId
      ).groupName;
    },
    async chooseCardSet(id, name) {
      this.loading = true;
      this.currentCardSetName = name;
      await this.fetchAllCards({ id });
      this.$emit("chooseCardSet", id);
      this.loading = false;
    },
    async addCardSet() {
      if (this.newCardSetName != "") {
        this.loading = true;
        await this.createNewCardSet({
          groupName: this.newCardSetName,
          folderId: this.folderId,
        });
        await this.fetchCardSets(this.folderId);
        this.cardSets = JSON.parse(JSON.stringify(this.getCardSets));
        this.newCardSetName = "";
        this.loading = false;
      }
    },
    async duplicate(set) {
      this.loading = true;
      await this.duplicateCardSet({
        groupName: set.groupName + " (copy)",
        folderId: this.folderId,
        groupCardId: set.id,
      });
      await this.fetchCardSets(this.folderId);
      this.cardSets = JSON.parse(JSON.stringify(this.getCardSets));
      let newCopiedSet = this.cardSets.slice(-1)[0];
      this.toggleRenameCardSet(newCopiedSet.id);
      this.loading = false;
    },
    async onDeleteCardSet() {
      this.loading = true;
      await this.deleteCardSet(this.deletingCardSetId);
      await this.fetchCardSets(this.folderId);
      this.isShowDialogDelete = false;
      this.loading = false;
    },
    async toggleCardSets() {
      this.loading = true;
      await this.fetchCardSets(this.folderId);
      this.cardSets = JSON.parse(JSON.stringify(this.getCardSets));
      this.loading = false;
    },
    onDeleteCardSetDialog(setId) {
      this.deletingCardSetId = setId;
      this.isShowDialogDelete = true;
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
    async openAssignDialog(item) {
      this.loading = true;
      await this.fetchUnassignedClasses(item.id);
      this.assigningGroupCard = item.id;
      this.assignMaxCards = item.numberOfCards;
      this.isShowDialogAssign = true;
      this.loading = false;
    },
    async moveCartSet(set) {
      this.loading = true;
      await this.fetchFolderList();
      this.movingGroupCardId = set.id;
      this.isShowDialogMove = true;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 8px 0 26px;
  position: relative;
}

.folder-table-header {
  background-color: #453fe3;
  height: 48px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 10px;
  cursor: pointer;
}

.folder-table-list {
  position: absolute;
  background: white;
  border: 1.5px solid #453fe3;
  border-top: none;
  border-radius: 4px;
  width: 100%;
  max-height: 70vh;
  overflow: auto;

  .folder-table-row {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;

    &.cursor-pointer:hover {
      background-color: #ccc;
    }

    .folder-name {
      margin: 0;
      padding: 0 10px;
      cursor: text;
      max-width: calc(100% - 70px);
    }

    .button-group {
      justify-content: center;
      align-items: center;
      gap: 3px;

      p {
        padding: 0px;
        text-align: center;
        margin: 0px;
      }

      * {
        width: 20px;
        transition: transform 0.2s;
      }

      *:hover {
        transform: scale(1.2);
      }
    }
  }

  .add-new-folder {
    padding: 0 5px;

    &:focus {
      outline: none;
    }
  }
}
</style>

<style lang="scss">
input:focus-visible {
  border-color: hsl(218, 81.6%, 56.9%) !important;
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
