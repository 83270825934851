<template>
  <v-container fluid>
    <v-overlay
      :value="loading"
      class="align-center justify-center"
      style="z-index: 10"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row v-if="!loading" class="mt-0">
      <v-col :md="showDialogCreateProCard ? 8 : 12" :sm="12">
        <v-card elevation="0" class="d-flex justify-space-between mb-3">
          <ButtonComponent
            v-if="isAdmin"
            class="d-flex-center"
            title="Back"
            @click="$router.go(-1)"
          />
          <v-card-title
            class="pa-0"
            :class="{
              'd-flex align-items-baseline': $vuetify.breakpoint.smOnly,
            }"
          >
            <h4 v-if="$vuetify.breakpoint.smAndUp">{{ title }}</h4>
            <h5 v-if="$vuetify.breakpoint.xsOnly">{{ title }}</h5>
            <p
              v-if="!isAdmin"
              class="remain"
              :class="[
                $vuetify.breakpoint.smAndDown && 'mobile',
                $vuetify.breakpoint.xsOnly && 'fs13',
              ]"
            >
              Còn {{ learnCardLeft }} câu hỏi trong {{ estimateTime }}
            </p>
          </v-card-title>
          <div v-if="isAdmin" class="d-flex-center gap">
            <v-menu
              ref="menu"
              v-model="showDatepicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDateyyyyMMddHHmmss(date, 'dddd/MM/yyyy')"
                  label="Ngày học"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :allowed-dates="allowedDates"
                :max="formatDateyyyyMMddHHmmss(new Date(), 'yyyy-MM-dddd')"
                :min="formatDateyyyyMMddHHmmss(getLastDay(30), 'yyyy-MM-dddd')"
                @change="handleDatePicker"
              ></v-date-picker>
            </v-menu>
            <v-btn class="ma-2" color="primary" dark @click="screenshot">
              Screenshot
              <v-icon dark right> mdi-download-circle </v-icon>
            </v-btn>
            <ButtonComponent title="Browse" isGrayBtn @click="handleGoBrowse" />
          </div>
          <ButtonComponent
            v-else
            title="Browse"
            isGrayBtn
            @click="handleGoBrowse"
          />
        </v-card>
        <slot :openAddCardForm="() => (showDialogCreateProCard = true)"></slot>
        <v-flex class="mt-9 d-flex" :class="'justify-center'">
          <p class="captionOne" style="margin-bottom: 0px">
            <strong
              >Đã trả lời {{ cardCount }} câu hỏi trong
              {{ studyTime.toFixed(1) }} phút</strong
            >
            ({{ studySpeed }}s/câu)
          </p>
        </v-flex>
      </v-col>
      <v-col
        v-if="isListForProStudent && showDialogCreateProCard"
        md="4"
        sm="7"
      >
        <transition-group name="fade" v-if="isListForProStudent">
          <template v-if="showDialogCreateProCard">
            <FormAddNewCard
              key="add"
              @close="
                hasUnsavedNewCard
                  ? (showDialogCancelAddCard = true)
                  : (showDialogCreateProCard = false)
              "
              @onCardCreated="$emit('onCardCreated')"
              @change="(hasChange) => (hasUnsavedNewCard = hasChange)"
              @createNewCardGroup="setSlotParentEventController"
            />
          </template>
        </transition-group>
      </v-col>
    </v-row>

    <DialogComponent
      typeDialog="confirm"
      description="Loại bỏ thay đổi và không tạo thẻ nữa?"
      :showDialogValue="showDialogCancelAddCard"
      @closeDialog="showDialogCancelAddCard = false"
      @confirmRequest="
        showDialogCancelAddCard = false;
        showDialogCreateProCard = false;
      "
    />
  </v-container>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import {
  convertSecondsToTime,
  formatDateyyyyMMddHHmmss,
  getLastDay,
} from "@/uttil";
import FormAddNewCard from "@/views/admin/FormAddNewCard.vue";
import DialogComponent from "@/components/ui/DialogComponent.vue";
export default {
  name: "LayoutList",
  components: { ButtonComponent, FormAddNewCard, DialogComponent },
  props: {
    title: {
      type: String,
      default: "",
    },
    learningResult: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDialogCreateProCard: false,
      showDialogCancelAddCard: false,
      hasUnsavedNewCard: false,
      showDatepicker: false,
      date: formatDateyyyyMMddHHmmss(new Date(), "yyyy-MM-dddd"),
      cloneListCardGroup: this.getListCardGroup,
      getLastDay,
      formatDateyyyyMMddHHmmss,
      base64: null,
    };
  },
  computed: {
    ...mapGetters("list", ["getListCardGroup"]),
    ...mapState("list", ["timeStudyAvg"]),
    isAdmin() {
      return this.$route.meta.role;
    },
    studentId() {
      return this.$route.params.studentId;
    },
    cardCount() {
      return this.learningResult?.cardCount;
    },
    studyTime() {
      return Math.round((this.learningResult?.studyTime / 60) * 100) / 100;
    },
    studySpeed() {
      return this.learningResult?.studyTime
        ? (this.learningResult?.studyTime / this.cardCount).toFixed(1)
        : 0;
    },
    learnCardLeft() {
      let newCard = 0;
      let revisingCard = 0;
      let cardGroups = this.getListCardGroup?.cardGroups || [];
      for (let userGroupCard of cardGroups) {
        newCard += userGroupCard.newCard || 0;
        revisingCard += userGroupCard.revisingCard || 0;
      }
      let proFolders = this.getListCardGroup?.proFolders || [];
      for (let folder of proFolders) {
        newCard += folder.newCard || 0;
        revisingCard += folder.revisingCard || 0;
      }
      return 3 * newCard + revisingCard;
    },
    estimateTime() {
      return convertSecondsToTime(this.learnCardLeft * this.timeStudyAvg);
    },
    isListForProStudent() {
      return this.getListCardGroup?.proFolders?.length || this.$isProStudent;
    },
  },
  methods: {
    ...mapMutations("list", [
      "setSlotParentEventController",
      "SET_CARD_GROUP_LIST",
    ]),
    ...mapActions("admin", ["getLearningHistory"]),
    handleGoBrowse() {
      this.$emit("goBrowse");
    },
    allowedDates(date) {
      const now = new Date();

      // Lấy giờ và phút hiện tại
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Tính tổng số phút trong ngày
      const totalMinutesNow = hours * 60 + minutes;
      const cutoffMinutes = 3 * 60 + 40; // 3:40 AM = 220 phút

      // Nếu trước 3:40 AM, lấy ngày hôm qua làm mốc
      if (totalMinutesNow < cutoffMinutes) {
        now.setDate(now.getDate() - 1);
      }
      return totalMinutesNow < cutoffMinutes
        ? date !== formatDateyyyyMMddHHmmss(now, "yyyy-MM-dddd")
        : true;
    },
    handleDatePicker(date) {
      this.$refs.menu.save(date);
      const isToday =
        date === formatDateyyyyMMddHHmmss(new Date(), "yyyy-MM-dddd");
      if (isToday) this.SET_CARD_GROUP_LIST(this.cloneListCardGroup);
      else this.getLearningHistory();
    },
    async getLearningHistory() {
      try {
        const { data } = this.getLearningHistory({
          studentId: this.studentId,
          date: formatDateyyyyMMddHHmmss(this.date, "dddd-MM-yyyy"),
        });
        this.cloneListCardGroup = this.getListCardGroup;
        this.SET_CARD_GROUP_LIST(JSON.parse(data));
      } catch (error) {
        console.log(error);
      }
    },
    async screenshot() {
      const el = document.body;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: "dataURL",
      };
      this.output = await this.$html2canvas(el, options);
      var a = document.createElement("a"); //Create <a>
      a.href = this.output; //Image Base64 Goes here
      a.download = this.getListCardGroup.fullName + "_" + this.date; //File name Here
      a.click(); //Downloaded file
    },
  },
};
</script>

<style lang="scss" scoped>
.gap {
  gap: 20px;
  .v-text-field {
    padding-top: 28px;
  }
}
.align-items-baseline {
  gap: 10px;
  align-items: baseline;
}
.remain {
  margin: 5px 0 0 8px;
  font-size: 16px;
  color: #384961;
  &.mobile {
    margin: 0px;
    font-size: 16px;
    line-height: 21px;
    &.fs13 {
      font-size: 13px;
    }
  }
}
</style>
