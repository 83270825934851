<template>
  <v-row justify="center">
    <!-- ---------- Confirm dialog------------ -->
    <v-dialog
      v-if="typeDialog === 'confirm'"
      :value="showDialogValue"
      max-width="400"
      @click:outside="handleCloseDialog"
    >
      <v-card class="pa-8">
        <v-card-title class="pa-0 mb-7">
          <h5 class="dialog-delete-title confirm" v-text="description" />
        </v-card-title>
        <v-card-text v-if="subDescription" class="pa-0 mb-7">
          <h6 class="dialog-delete-subtitle" v-text="subDescription"></h6>
        </v-card-text>
        <v-card-actions class="d-flex align-center pa-0">
          <v-spacer></v-spacer>
          <v-btn class="cancel-btn" elevation="0" @click="handleCloseDialog">
            <h6>{{ cancelBtnLabel }}</h6>
          </v-btn>

          <ButtonComponent
            :title="confirmBtnLabel"
            class="ml-3"
            @click="handleConfirmRequest"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ---------- Edit class dialog------------ -->
    <v-dialog
      v-if="typeDialog === 'edit-class'"
      :value="showDialogValue"
      max-width="400"
      @click:outside="handleCloseDialog"
    >
      <v-card class="pa-8">
        <v-card-title class="pa-0 mb-7">
          <h5 class="dialog-delete-title" v-text="title" />
        </v-card-title>
        <v-card-text class="pa-0 mb-7">
          <InputComponent
            :inputProps="{ placeholder: 'Nhập tên lớp' }"
            @input="(text) => (inputValue = text)"
            class="ma-0"
          />
        </v-card-text>
        <v-card-actions class="d-flex align-center pa-0">
          <v-spacer></v-spacer>
          <v-btn class="cancel-btn" elevation="0" @click="handleCloseDialog">
            <h6>Huỷ</h6>
          </v-btn>
          <ButtonComponent
            :title="'OK'"
            class="ml-3"
            @click="handleConfirmRequest"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ---------- Delete dialog------------ -->
    <v-dialog
      v-if="typeDialog === 'delete'"
      :value="showDialogValue"
      max-width="400"
      @click:outside="handleCloseDialog"
    >
      <v-card class="pa-8">
        <v-card-title class="pa-0 mb-7">
          <h5 class="dialog-delete-title" v-text="'Xoá thẻ?'" />
        </v-card-title>
        <v-card-text class="pa-0 mb-7">
          <h6
            class="dialog-delete-subtitle"
            v-text="'Thẻ sẽ bị xóa vĩnh viễn'"
          ></h6>
        </v-card-text>
        <v-card-actions class="d-flex align-center pa-0">
          <v-spacer></v-spacer>
          <v-btn class="cancel-btn" elevation="0" @click="handleCloseDialog">
            <h6>Huỷ</h6>
          </v-btn>
          <ButtonComponent
            title="Xoá"
            class="ml-3"
            @click="handleConfirmRequest"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ----------Success dialog && Firework dialog------------ -->
    <v-dialog
      v-if="typeDialog === 'success' || typeDialog === 'complete'"
      :value="showDialogValue"
      max-width="400"
      @click:outside="handleCloseDialog"
    >
      <v-card
        class="pa-8 d-flex flex-column"
        :class="{ bgsize: typeDialog === 'complete' }"
        style="border-radius: 12px"
      >
        <v-flex class="mb-6 d-flex justify-center">
          <img
            :src="`@/assets/images/${
              typeDialog === 'success' ? 'success' : 'complete'
            }.svg`"
          />
        </v-flex>
        <v-card-title class="pa-0 mb-7 text-center">
          <h5 v-if="typeDialog === 'success'" class="dialog-success-title">
            Chúc mừng bạn
            <br />
            đã hoàn thành bộ thẻ
          </h5>
          <h5 v-if="typeDialog === 'complete'" class="dialog-success-title">
            Chúc mừng bạn đã hoàn thành
            <br />
            bài tập về nhà hôm nay
          </h5>
        </v-card-title>
        <v-card-actions class="d-flex justify-center pa-0">
          <ButtonComponent title="OK" @click="handleCloseDialog" />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ---------- Warning dialog ------------ -->
    <v-dialog
      v-if="typeDialog === 'warning'"
      :value="showDialogValue"
      max-width="400"
      @click:outside="handleCloseDialog"
    >
      <v-card
        class="d-flex flex-column"
        :class="autoClose ? 'pa-5' : 'pa-8'"
        style="border-radius: 12px"
      >
        <v-card-title class="pa-0 text-center" :class="autoClose ?? 'mb-7'">
          <h5 class="dialog-success-title" v-html="description"></h5>
        </v-card-title>
        <v-card-actions v-if="!autoClose" class="d-flex justify-center pa-0">
          <ButtonComponent title="OK" @click="handleCloseDialog" />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ----------Review random dialog------------ -->
    <v-dialog
      v-if="typeDialog === 'remind' || typeDialog === 'customRemind'"
      :value="showDialogValue"
      max-width="600"
      @click:outside="handleCloseDialog"
    >
      <v-card class="pa-8">
        <v-card-title class="pa-0 mb-7">
          <h4
            class="dialog-delete-title"
            v-text="
              typeDialog === 'customRemind'
                ? 'Ôn thêm ngẫu nhiên'
                : 'Ôn tập trước'
            "
          ></h4>
        </v-card-title>
        <v-card-text class="pa-0 mb-7 d-flex flex-column">
          <h6
            class="dialog-delete-subtitle mb-1"
            v-text="
              typeDialog === 'customRemind'
                ? 'Bạn muốn ôn ngẫu nhiên bao nhiêu thẻ?'
                : 'Bạn muốn ôn tập trước bao nhiêu ngày?'
            "
          ></h6>
          <div
            class="dialog-helper-text text-averta-400"
            v-text="
              typeDialog === 'customRemind'
                ? `Nhập số thẻ từ 1 - [${maxInputStudy}]`
                : 'Nhập số ngày ≤ 3'
            "
          ></div>
          <v-checkbox
            v-if="typeDialog == 'remind'"
            v-model="reviewAheadCheck"
            :hide-details="true"
            class="reviewAheadCheck"
          >
            <span slot="label" style="color: #384961; font-weight: 600">
              Áp dụng cho tất cả bộ thẻ
            </span>
          </v-checkbox>
        </v-card-text>
        <v-card-actions
          :class="$vuetify.breakpoint.xsOnly ? 'flex-wrap' : ''"
          class="d-flex align-center pa-0"
        >
          <input
            type="number"
            v-model.number="inputStudy"
            v-bind:min="1"
            v-bind:max="maxInputStudy && maxInputStudy"
            @keyup.enter="handleStudy"
            class="ma-0 input-study"
          />
          <ButtonComponent
            title="Bắt đầu ôn"
            icon="fa-arrow-right"
            :class="$vuetify.breakpoint.xsOnly ? 'mt-6 ml-0' : 'ml-3'"
            @click="handleStudy"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    -----------------------------------   -->
    <!-- ---------------------Dialog for admin-------------------   -->
    <!--    -----------------------------------   -->

    <!-- ----------Add and edit admin user------------ -->
    <v-dialog
      v-if="typeDialog === 'add-admin'"
      :value="showDialogValue"
      max-width="400"
      @click:outside="handleCloseDialog"
    >
      <form>
        <v-card class="pa-8">
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <label>
                <h6>Họ và tên admin{{ isAddingSubAdmin ?? " phụ" }}</h6>
              </label>

              <input
                ref="addAccount"
                type="text"
                v-model="account.fullName"
                class="mt-3"
              />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <label>
                <h6>Username</h6>
              </label>
              <input type="text" v-model="account.userName" class="mt-3" />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <label>
                <h6>Password</h6>
              </label>
              <input type="text" v-model="account.password" class="mt-3" />
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-card-actions class="d-flex align-center justify-end pa-0 mt-6">
            <v-btn class="cancel-btn" elevation="0" @click="handleCloseDialog">
              <h6>Huỷ</h6>
            </v-btn>
            <ButtonComponent
              :title="'OK'"
              class="ml-3"
              :disable="isInvalidAccount"
              @click="handleSaveAccount"
            />
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- ----------Add and edit student user------------ -->
    <v-dialog
      v-if="typeDialog === 'add-student'"
      :value="showDialogValue"
      max-width="400"
      @click:outside="handleCloseDialog"
    >
      <form>
        <v-card class="pa-8">
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <label>
                <h6>Họ và tên</h6>
              </label>
              <input
                ref="addAccount"
                type="text"
                v-model="account.fullName"
                class="mt-3"
              />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <label>
                <h6>Lớp</h6>
              </label>
              <v-select
                :items="itemsSelect"
                item-text="className"
                item-value="id"
                outlined
                background-color="#F9FBFC"
                class="mt-3 mb-n6"
                v-model="account.classId"
                label="Chọn lớp"
                single-line
                variant="solo"
                clearable
              />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <label>
                <h6>Username</h6>
              </label>
              <InputComponent v-model="account.userName" class="mt-3" />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <label>
                <h6>Password</h6>
              </label>
              <InputComponent
                v-model="account.password"
                :disable="isInvalidAccount"
                class="mt-3"
              />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <label>
                <h6>Cho phép tạo thẻ</h6>
              </label>
              <v-switch
                v-model="account.isPro"
                :label="account.isPro ? 'Cho phép' : 'Không cho phép'"
                :false-value="false"
                :true-value="true"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-card-actions class="d-flex align-center justify-end pa-0 mt-6">
            <v-btn class="cancel-btn" elevation="0" @click="handleCloseDialog">
              <h6>Huỷ</h6>
            </v-btn>
            <ButtonComponent
              :title="'OK'"
              class="ml-3"
              @click="handleSaveAccount"
            />
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>

    <!-- ----------Manage classes dialog------------ -->
    <v-dialog
      v-if="typeDialog === 'manage-classes'"
      :value="showDialogValue"
      max-width="400"
      @click:outside="handleCloseDialog"
    >
      <v-card class="pa-8">
        <v-card-title class="pa-0 mb-5">
          <h5 class="dialog-delete-title" v-text="'Danh sách lớp'" />
        </v-card-title>
        <v-card-text class="pa-0 mb-4">
          <div class="d-flex justify-space-between mb-2">
            <input
              type="text"
              placeholder="Nhập tên lớp muốn tạo và nhấn Enter"
              class="add-new-folder"
              v-model="newClassName"
              @keyup.enter="addClass"
            />
            <img
              v-if="newClassName != ''"
              class="cursor-pointer"
              :src="getImgUrl('icons/trash.svg')"
              @click="newClassName = ''"
            />
          </div>
          <div v-if="!itemsSelect.length" class="pl-4">Không có lớp nào.</div>
          <div
            v-for="item in itemsSelect"
            :key="item.id"
            class="d-flex py-4 justify-space-between"
          >
            <h6 v-text="item.className"></h6>
            <v-menu offset-y transition="slide-y-transition" location="end">
              <!-- -------------------Menu list-------------------- -->
              <template v-slot:activator="{ attrs, on }">
                <img
                  src="@/assets/icons/menu.svg"
                  style="cursor: pointer; padding: 15px 2px"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-list class="pa-0 layout-list" style="min-width: 180px">
                <v-list-item
                  class="px-5 py-1"
                  v-for="(option, index) in optionsManageClasses"
                  :key="index"
                  link
                  @click="handleEmit(option.emitFunction, item)"
                >
                  <v-list-item-title class="ml-4 d-flex">
                    {{ option.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Set reminder time -->
    <v-dialog
      v-if="typeDialog === 'set-reminder'"
      :value="showDialogValue"
      max-width="400"
      @click:outside="handleCloseDialog"
    >
      <form>
        <v-card class="pa-8">
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <label>
                <h6>Nhập Reminder Time:</h6>
              </label>
              <input type="text" v-model="reminder.reminderTime" class="mt-3" />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <label>
                <h6>Nhập Qn</h6>
              </label>
              <input type="text" v-model="reminder.Qn" class="mt-3" />
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-card-actions class="d-flex align-center justify-end pa-0 mt-6">
            <v-btn class="cancel-btn" elevation="0" @click="handleCloseDialog">
              <h6>Huỷ</h6>
            </v-btn>
            <ButtonComponent
              :title="'OK'"
              class="ml-3"
              :disable="!reminder.Qn && !reminder.reminderTime"
              @click="handleReschedule"
            />
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>

    <!-- Student create, edit pro card group -->
    <v-dialog
      v-if="
        ['student-create-card-group', 'student-rename-card-group'].includes(
          typeDialog
        )
      "
      :value="showDialogValue"
      max-width="400"
      persistent
    >
      <v-card class="pa-8">
        <v-card-title class="pa-0 mb-7">
          <h5
            class="dialog-delete-title confirm"
            v-text="
              typeDialog === 'student-create-card-group'
                ? 'Tạo bộ thẻ mới'
                : 'Đổi tên bộ thẻ'
            "
          />
        </v-card-title>
        <v-row>
          <v-col
            v-if="typeDialog === 'student-create-card-group'"
            cols="12"
            md="12"
            sm="12"
          >
            <label>
              <h6>Chọn thư mục</h6>
            </label>
            <v-select
              :items="itemsSelect"
              item-text="folderName"
              item-value="folderId"
              outlined
              background-color="#F9FBFC"
              class="mt-3 mb-n6"
              v-model="proGroupCard.folderId"
              label="Chọn thư mục"
              single-line
              variant="solo"
              clearable
            />
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <label>
              <h6>Tên bộ thẻ</h6>
            </label>
            <InputComponent
              v-model="proGroupCard.groupName"
              :inputProps="{ placeholder: 'Tên bộ thẻ' }"
              class="mt-3"
            />
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-card-actions class="d-flex align-center justify-end pa-0 mt-6">
          <v-btn
            class="cancel-btn"
            elevation="0"
            @click="closeDialogCreateGroupCard"
          >
            <h6>Huỷ</h6>
          </v-btn>
          <ButtonComponent
            :title="
              typeDialog === 'student-create-card-group' ? 'Tạo mới' : 'Đổi tên'
            "
            class="ml-3"
            :disable="!isGroupNameChanged"
            @click="handleConfirmRequest(proGroupCard)"
          />
        </v-card-actions>
      </v-card>
      <v-dialog
        v-if="confirmDialog"
        :value="confirmDialog"
        max-width="400"
        @click:outside="confirmDialog = false"
      >
        <v-card class="pa-8 d-flex flex-column" style="border-radius: 12px">
          <v-card-title class="pa-0 mb-7 text-center">
            <h5
              class="dialog-success-title"
              v-html="
                'Bạn có thay đổi chưa lưu, bạn chắc chắn muốn huỷ tạo bộ thẻ Pro chứ?'
              "
            ></h5>
          </v-card-title>
          <v-card-actions class="d-flex justify-center pa-0">
            <v-spacer></v-spacer>
            <v-btn
              class="cancel-btn"
              elevation="0"
              @click="confirmDialog = false"
            >
              <h6>Hủy</h6>
            </v-btn>
            <ButtonComponent title="OK" @click="handleCloseDialog" />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-row>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import InputComponent from "@/components/ui/InputComponent.vue";
import { mapActions, mapState } from "vuex";
import { PRO_FOLDER_LIST, STUDENT_PRO_STATUS_LIST } from "@/constants/index";

export default {
  components: { InputComponent, ButtonComponent },
  props: {
    title: {
      type: String,
      default: "",
    },
    showDialogValue: {
      type: Boolean,
      default: false,
    },
    typeDialog: {
      type: String,
      default: "delete",
    },
    itemsSelect: {
      type: [],
      default: () => [],
    },
    description: {
      type: String,
      default: "Bạn có chắc chắn muốn thực hiện hành động này?",
    },
    subDescription: {
      type: String,
    },
    cancelBtnLabel: {
      type: String,
      default: "Huỷ",
    },
    confirmBtnLabel: {
      type: String,
      default: "Chắc chắn",
    },
    initialGroupName: {
      type: String,
      default: "",
    },
    maxInputStudy: {
      type: Number,
      default: 0,
    },
    editAccount: {
      type: Object,
      default: () => {},
    },
    isReviewAheadProFolder: {
      type: Boolean,
      default: false,
    },
    autoClose: {
      type: Boolean,
      default: false,
    },
    isAddingSubAdmin: {
      type: Boolean,
      default: false,
    },
    cardGroupsInProFolder: {
      type: [],
      default: () => [],
    },
  },
  data() {
    return {
      inputProps: {
        placeholder: "Nhập số thẻ",
      },
      optionsManageClasses: [
        {
          title: "Sửa",
          emitFunction: "editClass",
        },
        {
          title: "Xoá",
          emitFunction: "deleteClass",
        },
      ],
      newClassName: "",
      account: {},
      reminder: { reminderTime: "", Qn: "" },
      inputStudy: 1,
      reviewAheadCheck: false,
      proGroupCard: {
        folderId: PRO_FOLDER_LIST[1].folderId,
        groupName: "",
      },
      confirmDialog: false,
    };
  },
  watch: {
    showDialogValue: {
      handler() {
        if (this.editAccount?.id) {
          this.account = {
            ...this.editAccount,
            password: this.editAccount.passwordNotEncode,
            isPro: this.editAccount.status === STUDENT_PRO_STATUS_LIST.PRO,
            status: this.editAccount.status
              ? this.editAccount.status
              : STUDENT_PRO_STATUS_LIST.NON_PRO,
          };
        } else {
          this.account = {
            classId: 0,
            fullName: "",
            userName: "",
            password: "",
            isAdmin: this.isAddAdmin,
            isPro: false,
          };
        }
      },
    },
  },
  computed: {
    ...mapState("card", ["currentGroupCardId"]),
    isInvalidAccount() {
      return (
        this.account.fullName.length === 0 ||
        this.account.userName.length < 6 ||
        this.account.password.length < 6
      );
    },
    isAddAdmin() {
      return this.typeDialog == "add-admin";
    },
    studentId() {
      return this.$route.params.studentId;
    },
    isGroupNameChanged() {
      return this.typeDialog === "student-create-card-group"
        ? !!this.proGroupCard.groupName
        : this.proGroupCard.groupName !== this.initialGroupName;
    },
  },
  created() {
    this.account = {
      classId: 0,
      fullName: "",
      userName: "",
      password: "",
      isAdmin: this.isAddAdmin,
      isSubAdmin: this.isAddAdmin && this.isAddingSubAdmin,
    };
    if (this.initialGroupName) {
      this.proGroupCard.groupName = this.initialGroupName;
    }
  },
  methods: {
    ...mapActions("admin", [
      "createNewClass",
      "fetchClasses",
      "createNewAccount",
      "fetchAccounts",
      "updateAccount",
    ]),
    ...mapActions("list", [
      "fetchStudentCardGroupList",
      "createRandomStudy",
      "reviewAhead",
      "reviewAheadFolder",
    ]),
    closeDialogCreateGroupCard() {
      if (this.isGroupNameChanged) {
        this.confirmDialog = true;
      } else this.$emit("closeDialog");
      // this.proGroupCard.groupName = "";
    },
    handleCloseDialog() {
      this.$emit("closeDialog");
    },
    handleReschedule() {
      this.$emit("submit", this.reminder);
      this.reminder.reminderTime = "";
      this.reminder.Qn = "";
    },
    handleConfirmRequest(payload = "") {
      this.$emit("confirmRequest", payload || this.inputValue);
      this.proGroupCard.groupName = "";
    },
    handleStudy() {
      if (this.inputStudy > 0 && this.inputStudy <= this.maxInputStudy) {
        if (this.typeDialog === "customRemind") {
          this.submitRandomStudy({
            studentId: this.studentId,
            count: this.inputStudy,
            userGroupCardSavedId: this.currentGroupCardId,
          });
        } else {
          const payloadReview = {
            days: this.inputStudy,
            studentId: this.studentId,
          };
          if (!this.reviewAheadCheck) {
            if (this.isReviewAheadProFolder)
              payloadReview.userCardGroupIds = this.cardGroupsInProFolder;
            else payloadReview.userGroupCardId = this.currentGroupCardId;
          }
          this.submitReviewAhead(payloadReview);
        }

        this.inputStudy = 1;
        this.handleCloseDialog();
      }
    },
    handleEmit(action, payload) {
      this.$emit(action, payload);
    },
    async addClass() {
      await this.createNewClass(this.newClassName);
      await this.fetchClasses();
      this.newClassName = "";
    },
    async handleSaveAccount() {
      if (this.account.id) {
        if (this.account.isPro) {
          this.account.status = STUDENT_PRO_STATUS_LIST.PRO;
        } else {
          this.account.status =
            this.account.status === STUDENT_PRO_STATUS_LIST.NON_PRO
              ? STUDENT_PRO_STATUS_LIST.NON_PRO
              : STUDENT_PRO_STATUS_LIST.USED_TO_PRO;
        }
        await this.updateAccount(this.account);
        this.handleCloseDialog();
      } else {
        await this.createNewAccount(this.account);
      }
      await this.fetchAccounts();
      this.account = {
        classId: 0,
        fullName: "",
        userName: "",
        password: "",
        isAdmin: null,
        isPro: null,
      };
      if (
        this.typeDialog === "add-student" ||
        this.typeDialog === "add-admin"
      ) {
        this.$refs.addAccount.focus();
      }
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
    async submitRandomStudy(payload) {
      const mess = await this.createRandomStudy(payload);
      if (!mess) {
        await this.fetchStudentCardGroupList({ studentId: this.studentId });
      }
    },
    async submitReviewAhead(payload) {
      if (this.isReviewAheadProFolder)
        await this.reviewAheadFolder({
          ...payload,
          userCardGroupIds: this.cardGroupsInProFolder,
        });
      else await this.reviewAhead(payload);
      await this.fetchStudentCardGroupList({ studentId: this.studentId });
      this.reviewAheadCheck = false;
    },
  },
  mounted() {
    const handleKeyPressed = (e) => {
      if (e.key === "Enter" && e.ctrlKey) {
        if (this.showDialogValue) {
          if (
            this.typeDialog === "add-student" ||
            this.typeDialog === "add-admin"
          ) {
            this.handleSaveAccount();
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeyPressed);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("keydown", handleKeyPressed);
    });
  },
};
</script>

<style lang="sass" scoped>
// ----- dialog delete --------- //
.dialog-delete-title
  font-size: 22px
  color: #1C283D

.dialog-delete-subtitle
  color: #384961

.cancel-btn
  background-color: transparent !important
  text-transform: initial
  color: #453FE3

// ------- dialog success ------- //
.v-card
  border-radius: 12px !important

.dialog-success-title
  font-size: 22px
  color: #384961
  word-break: normal
  line-height: 33px
  width: 100%

.v-responsive__content
  justify-content: center
  display: flex
.bgsize
  background-image: url(@/assets/images/firework-bg.svg)
  background-size: cover
</style>

<style lang="scss" scoped>
.dialog-delete-title.confirm {
  word-break: break-word;
}
.add-new-folder {
  height: 36px;
  &:focus {
    outline: none;
  }
}
input {
  width: 100%;
  padding: 16px 0 16px 16px;
  border: 1px solid #d9dfea;
  border-radius: 12px;
  background-color: #f9fbfc;
  min-width: 305px;
  &.smd {
    min-width: unset;
  }
}
.allocate-input {
  margin: 0 10px;
  width: 50px;
  border: 1px solid black;
  padding: 1px;
}
.reviewAheadCheck {
  margin-top: 8px;
  margin-left: -2px;
}
.input-study {
  min-width: unset;
}
</style>
