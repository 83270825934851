import moment from "moment";
const isEmpty = (value) => {
  return (
    typeof value == "undefined" ||
    (typeof value == "string" && value.length == 0) ||
    (typeof value == "number" && value == 0) ||
    (typeof value == "boolean" && value == false) ||
    value === null
  );
};
const convertSecondsToAcronymTime = (seconds, short = true) => {
  if (seconds < 60) {
    return seconds + (short ? "s" : " giây");
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return minutes + (short ? "p" : " phút");
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return hours + (short ? "h" : " giờ");
  } else {
    const days = Math.floor(seconds / 86400);
    return days + (short ? "d" : " ngày");
  }
};
const formatBreakLineInEditor = (txt) => {
  if (!txt) return "";
  const newStr = txt
    .replace(/\n{2,}\s*/g, "\n")
    .replace(/\r\n{2,}\s*/g, "\r\n")
    .replace(/<p><br data-cke-filler="true"><p>/g, "")
    .replace(/<br data-cke-filler="true">/g, "")
    .replaceAll("<p><br>&nbsp;</p>", "")
    .replaceAll("<p>&nbsp;</p>", "")
    .replaceAll("<br>&nbsp;</p>", "</p>")
    .replaceAll("<p></p>", "")
    .replaceAll(`style="color:rgb(0,0,0);"`, "")
    .replaceAll(`style=\\"color:rgb(0,0,0);\\"`, "");
  return newStr;
};
const formatSubday = (subday, formatDate) => {
  return moment().subtract(subday, "d").format(formatDate);
};
const convertSecondsToTime = (seconds) => {
  if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.ceil((seconds % 3600) / 60);
    return `${hours} giờ ${minutes} phút`;
  } else {
    const minutes = Math.ceil(seconds / 60);
    return `${minutes} phút`;
  }
};
const checkTimeMoreThanToday = (btnValue, card) => {
  const fromCurr = new Date();
  let endOfToday = moment().toDate().setHours(4, 0, 0);
  if (fromCurr > endOfToday) {
    endOfToday = moment().add(1, "days").toDate().setHours(4, 0, 0);
  }
  const btnObj = {
    0: "QnRelearn",
    1: "QnEasy",
    2: "QnGood",
    3: "QnHard",
  };
  const qn = card[btnObj[btnValue]] ?? 600;
  return moment(fromCurr).add(qn, "seconds") >= endOfToday;
};
// Custom function to format the date
function formatDateyyyyMMddHHmmss(dateString, format = "dddd/MM/yy, HH:mm:ss") {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  switch (format) {
    case "dddd/MM/yy, HH:mm:ss":
      return `${day}/${month}/${year.slice(
        -2
      )}, ${hours}:${minutes}:${seconds}`;
    case "dddd/MM/yyyy":
      return `${day}/${month}/${year}`;
    case "yyyy-MM-dddd":
      return `${year}-${month}-${day}`;
    case "dddd-MM-yyyy":
      return `${day}-${month}-${year}`;
    default:
      return `${day}/${month}/${year.slice(
        -2
      )}, ${hours}:${minutes}:${seconds}`;
  }
}

const checkDoublePress = (callback) => {
  // Kiểm tra nếu đã có lần nhấn phím trước đó
  let keyPressTime = null;
  const doubleKeyPressInterval = 500;
  if (keyPressTime) {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - keyPressTime;
    // Kiểm tra thời gian giữa hai lần nhấn phím
    if (elapsedTime <= doubleKeyPressInterval) {
      // Thực hiện các hành động khi double keydown (X) xảy ra
      callback();
    }
    // Reset lại biến keyPressTime
    keyPressTime = null;
  } else {
    // Gán thời gian cho lần nhấn phím đầu tiên
    keyPressTime = new Date().getTime();
  }
};

function searchWithoutAccentAndCase(text, query) {
  // Loại bỏ dấu tiếng Việt và chuyển về chữ thường
  const normalize = (str) =>
    str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

  const normalizedText = normalize(text);
  const normalizedQuery = normalize(query);

  // Tìm kiếm
  return normalizedText.includes(normalizedQuery);
}

function validateInputTypeNumber(e) {
  if (["e", ".", "-"].includes(e.data)) {
    e.preventDefault();
    return false;
  } else {
    return true;
  }
}

function getLastDay(daysBefore = 0, format = "yyyy-MM-dddd") {
  const now = new Date();

  // Lấy giờ và phút hiện tại
  const hours = now.getHours();
  const minutes = now.getMinutes();

  // Tính tổng số phút trong ngày
  const totalMinutesNow = hours * 60 + minutes;
  const cutoffMinutes = 3 * 60 + 40; // 3:40 AM = 220 phút

  // Nếu trước 3:40 AM, lấy ngày hôm qua làm mốc
  if (totalMinutesNow < cutoffMinutes) {
    now.setDate(now.getDate() - 1);
  }
  now.setDate(now.getDate() - daysBefore);

  // Hàm format ngày thành DD-MM-YYYY
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    switch (format) {
      case "dddd/MM/yyyy":
        return `${day}/${month}/${year}`;
      case "yyyy-MM-dddd":
        return `${year}-${month}-${day}`;
      default:
        return `${year}-${month}-${day}`;
    }
    // return `${d}-${m}-${y}`;
  };

  // Lấy ngày trước đó
  return formatDate(new Date(now.setDate(now.getDate() - 1)));
}

export {
  isEmpty,
  formatBreakLineInEditor,
  formatSubday,
  convertSecondsToTime,
  checkTimeMoreThanToday,
  convertSecondsToAcronymTime,
  formatDateyyyyMMddHHmmss,
  checkDoublePress,
  searchWithoutAccentAndCase,
  validateInputTypeNumber,
  getLastDay,
};
