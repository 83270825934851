export const LEARNING_RESULT_MAX_DAY = 30;
export const LEARNING_RESULT_RANGE = 7;
export const LEARNING_RESULT_DEFAULT_SORT = "ASC";
export const LEARNING_RESULT_DEFAULT_CHECKED = 13;
export const DEFAULT_TABLE_HEADER_MANAGE_LEARN = [
  {
    text: "Họ và tên",
    subText: "<User Name>",
    value: "fullName",
    sortable: false,
    isFilter: false,
    className: "colum-fullName",
  },
  {
    text: "Lớp",
    value: "className",
    sortable: false,
    isFilter: true,
    className: "colum-class",
  },
];
export const TABLE_HEADER_CARD_MANAGEMENT = [
  {
    text: "Thẻ",
    value: "word",
    sortable: false,
  },
  {
    text: "Đến hạn",
    value: "dueDate",
    sortable: false,
    align: "center",
  },
  {
    text: "Bộ thẻ",
    value: "cardSetName",
    sortable: false,
    align: "center",
  },
  {
    text: "Kiểu thẻ",
    value: "cardType",
    sortable: false,
    align: "center",
  },
  {
    text: "Học sinh",
    value: "studentName",
    sortable: false,
    align: "center",
  },
  {
    text: "...",
    value: "actions",
    sortable: false,
    align: "center",
  },
];
export const TABLE_LIST_ACTION_CARD_MANAGEMENT = [
  {
    title: "Convert",
    emitFunction: "convert",
  },
  {
    title: "Xoá",
    emitFunction: "delete",
  },
];
export const LIST_VIEW_TABLE_HEADERS = [
  {
    text: "Bộ thẻ",
    value: "groupName",
    sortable: false,
    class: "groupName",
  },
  { text: "Thẻ mới", value: "newCard", align: "center", sortable: false },
  {
    text: "Thẻ ôn tập",
    value: "revisingCard",
    align: "center",
    sortable: false,
  },
  {
    text: "",
    value: "actions",
    align: "center",
    sortable: false,
    class: "table-action",
  },
];
export const MOBILE_LIST_VIEW_TABLE_HEADERS = [
  {
    text: "Bộ thẻ",
    value: "groupName",
    sortable: false,
    class: "groupName",
  },
  {
    text: "",
    value: "count",
    align: "center",
    sortable: false,
    width: "95px",
  },
  {
    text: "",
    value: "actions",
    align: "center",
    sortable: false,
    class: "table-action",
    width: "45px",
  },
];
export const STUDENT_PRO_STATUS_LIST = {
  NON_PRO: 0,
  PRO: 1,
  USED_TO_PRO: 2,
};
export const ACCOUNT_ROLE = {
  STUDENT: 0,
  ADMIN: 1,
};
export const PRO_FOLDER_LIST = [
  {
    folderName: "LISTENING",
    folderId: 15,
  },
  {
    folderName: "READING",
    folderId: 16,
  },
  {
    folderName: "SPEAKING",
    folderId: 17,
  },
  {
    folderName: "WRITING",
    folderId: 18,
  },
];
export const FIELDS_NEED_EDITOR = ["word", "mean", "example"];

export const cardSetHeader = [
  {
    text: "Tất cả bộ thẻ",
    value: "customGroupName",
    sortable: false,
  },
];
//   ----------card set details data table----------
export const cardSetDetailHeaderAdmin = [
  {
    text: "Thẻ",
    value: "word",
    sortable: false,
  },
  {
    text: "Đến hạn",
    value: "reminderTime",
    sortable: false,
    align: "center",
    width: "78px",
  },
  {
    text: "Qn",
    value: "Qn",
    sortable: false,
    align: "center",
    width: "55px",
  },
  {
    text: "QnRelearn",
    value: "QnRelearn",
    sortable: false,
    align: "center",
    width: "77px",
  },
  {
    text: "QnHard",
    value: "QnHard",
    sortable: false,
    align: "center",
    width: "62px",
  },
  {
    text: "QnGood",
    value: "QnGood",
    sortable: false,
    align: "center",
    width: "65px",
  },
  {
    text: "QnEasy",
    value: "QnEasy",
    sortable: false,
    align: "center",
    width: "62px",
  },
  {
    text: "H",
    value: "goodLevelFactor",
    sortable: false,
    align: "center",
    width: "60px",
  },
  {
    text: "UpdatedAt",
    value: "updatedAt",
    sortable: false,
    align: "center",
    width: "85px",
  },
  {
    text: "Bộ thẻ",
    value: "cardGroupName",
    sortable: false,
    align: "center",
    width: "15%",
  },
  {
    text: "",
    value: "actions",
    sortable: false,
    align: "center",
    width: "35px",
  },
];
export const cardSetDetailHeader = [
  {
    text: "Thẻ",
    value: "word",
    sortable: false,
  },
  {
    text: "Đến hạn",
    value: "reminderTime",
    sortable: false,
    align: "center",
    width: "75px",
  },
  {
    text: "Bộ thẻ",
    value: "cardGroupName",
    sortable: false,
    align: "center",
    width: "20%",
  },
  {
    text: "",
    value: "actions",
    sortable: false,
    align: "center",
    width: "35px",
  },
];
export const cardSetDetailHeaderMobile = [
  {
    text: "Thẻ",
    value: "word",
    sortable: false,
  },
  {
    text: "Đến hạn",
    value: "reminderTime",
    sortable: false,
    align: "center",
    width: "80px",
  },
  {
    text: "",
    value: "actions",
    sortable: false,
    align: "center",
    width: "35px",
  },
];
//   ----------end of card set details data table----------

//   ----------manage account list data table----------
export const accountsHeader = [
  {
    text: "Họ và tên",
    value: "fullName",
    sortable: false,
  },
  {
    text: "Username",
    value: "userName",
    sortable: false,
    align: "center",
  },
  {
    text: "Password",
    value: "passwordNotEncode",
    sortable: false,
    align: "center",
  },
  {
    text: "Lớp",
    value: "className",
    sortable: false,
    isFilter: true,
    align: "center",
  },
  {
    text: "Ngày thêm",
    value: "createdAt",
    sortable: false,
    align: "center",
  },
  {
    text: "",
    value: "actions",
    sortable: false,
    align: "center",
  },
];
export const accountsOptions = [
  {
    title: "Sửa",
    emitFunction: "edit",
    icon: "pencil",
  },
  {
    title: "Xoá",
    emitFunction: "delete",
    icon: "trash",
  },
];
//   ---------- end of manage account list data table----------
