<template>
  <div class="container">
    <div class="d-flex justify-between align-center">
      <p>
        Kiểu
        {{
          [1, 4].includes(currentTypeCard)
            ? 1
            : [2, 5].includes(currentTypeCard)
            ? 2
            : 3
        }}
      </p>
      <p>
        {{ currentStep.title }}
      </p>
    </div>
    <template v-if="currentStep.isFrontSide">
      <!--------------- Question title ----------------- -->
      <div
        class="mb-2 justify-center d-flex front-side"
        :class="{
          'mb-4': $vuetify.breakpoint.xsOnly,
        }"
      >
        <div class="col justify-center">
          <div
            v-if="currentTypeCard == 1 && currentCard.audioLink"
            class="d-flex justify-content-center"
          >
            <img
              class="cursor-pointer"
              @click="handlePlayAudio"
              src="@/assets/icons/audio.svg"
            />
            <audio
              v-if="currentCard.audioLink !== null"
              :src="currentCard.audioLink"
              ref="audioPlayer"
              preload="auto"
              autoplay
              muted
            />
          </div>
          <div
            v-else-if="
              [4, 5].includes(currentTypeCard) ||
              ([1, 2].includes(currentTypeCard) && currentStepNo == 3)
            "
            class="d-flex justify-content-center"
          >
            <p
              class="word"
              :class="{
                fw400: currentTypeCard == 5,
                fw600:
                  currentTypeCard == 4 ||
                  (currentTypeCard == 1 && currentStepNo == 3),
                mobile: $vuetify.breakpoint.xsOnly,
              }"
              v-html="
                currentTypeCard == 4 ||
                (currentTypeCard == 1 && currentStepNo == 3)
                  ? currentCard.mean
                  : formatBreakLineInEditor(currentCard.meanFormatted)
              "
            ></p>
          </div>
          <div
            v-else-if="[2, 3].includes(currentTypeCard)"
            class="d-flex justify-content-center"
          >
            <p
              class="word fw400"
              :class="{
                mobile: $vuetify.breakpoint.xsOnly,
              }"
              v-html="currentCard.wordFormatted"
            ></p>
          </div>
          <div
            v-if="
              ([1, 4].includes(currentTypeCard) || currentStepNo == 3) &&
              currentCard.wordType != 'null' &&
              !!currentCard.wordType
            "
          >
            <p
              class="text-center word-type"
              :class="{
                mobile: $vuetify.breakpoint.xsOnly,
              }"
              style="color: #f56742"
            >
              ({{ currentCard.wordType }})
            </p>
          </div>
        </div>
      </div>
      <!----------------------Input answer------------------- -->
      <div class="front-side">
        <div class="mb-2">
          <label for="answer">
            <h6>Câu trả lời:</h6>
          </label>
        </div>
        <InputComponent
          @blur="isFocus = false"
          @focus="isFocus = true"
          :inputProps="answerInput"
          v-model="answerValue"
          :autoFocus="
            (currentTypeCard == 4 ||
              (currentStepNo == 3 && currentTypeCard == 1)) &&
            $vuetify.breakpoint.smAndUp
          "
        />
      </div>
    </template>

    <template v-else>
      <template v-if="currentTypeCard == 1">
        <div class="justify-center d-flex mb-2">
          <p
            class="mean"
            :class="{ mobile: $vuetify.breakpoint.smAndDown }"
            v-html="currentCard.mean"
          ></p>
        </div>
        <div class="text-center">
          <p
            class="word"
            :class="{
              mobile: $vuetify.breakpoint.smAndDown,
              'mt-1': $vuetify.breakpoint.smAndUp,
            }"
            v-html="currentCard.word"
          ></p>
        </div>
        <div
          class="audio d-flex-center"
          :class="{
            mobile: $vuetify.breakpoint.smAndDown,
            'mt-2': $vuetify.breakpoint.smAndUp,
          }"
        >
          <div :class="$vuetify.breakpoint.smAndDown && 'img-wrapper'">
            <img
              v-if="currentCard.audioLink"
              class="cursor-pointer"
              @click="handlePlayAudio"
              src="@/assets/images/volumn.svg"
              :style="
                $vuetify.breakpoint.smAndDown && {
                  width: '16px',
                  height: '16px',
                }
              "
            />
          </div>
          <audio
            v-if="currentCard.audioLink !== null"
            :src="currentCard.audioLink"
            ref="audioPlayer"
            preload="auto"
            autoplay
            muted
          />
          <p v-if="currentCard.spelling !== 'null'" class="spelling">
            {{ currentCard.spelling }}
          </p>
          <p
            v-if="currentCard.wordType !== 'null'"
            style="color: #f56742"
            class="word-type"
          >
            ({{ currentCard.wordType }})
          </p>
        </div>
        <div
          class="text-center"
          :class="{
            'mobile mt-3': $vuetify.breakpoint.smAndDown,
            'mt-4': $vuetify.breakpoint.smAndUp,
          }"
        >
          <p v-html="currentCard.exampleFormatted" class="example"></p>
        </div>
      </template>

      <template
        v-else-if="
          currentTypeCard == 4 || (currentTypeCard == 1 && currentStepNo == 4)
        "
      >
        <div class="mt-1 text-center">
          <p
            class="word word28 d-flex-center correct"
            :class="$vuetify.breakpoint.smAndDown ? 'mobile' : ''"
            style="gap: 5px; font-weight: 700"
          >
            {{ currentCard.word }}
            <v-icon class="correct">mdi-arrow-right</v-icon>
            {{ currentCard.word }}
          </p>
        </div>
        <div
          class="audio d-flex-center"
          :class="{
            mobile: $vuetify.breakpoint.smAndDown,
            'mt-2': $vuetify.breakpoint.smAndUp,
          }"
        >
          <div :class="$vuetify.breakpoint.smAndDown && 'img-wrapper'">
            <img
              v-if="currentCard.audioLink !== null"
              class="cursor-pointer"
              @click="handlePlayAudio"
              src="@/assets/images/volumn.svg"
              :style="
                $vuetify.breakpoint.smAndDown && {
                  width: '16px',
                  height: '16px',
                }
              "
            />
          </div>
          <audio
            v-if="currentCard.audioLink !== null"
            :src="currentCard.audioLink"
            ref="audioPlayer"
            preload="auto"
            autoplay
            muted
          />
          <p v-if="currentCard.spelling !== 'null'" class="spelling">
            {{ currentCard.spelling }}
          </p>
          <p
            v-if="currentCard.wordType !== 'null'"
            style="color: #f56742"
            class="word-type"
          >
            ({{ currentCard.wordType }})
          </p>
        </div>
        <p
          class="mean mean22 mt-3"
          :class="{
            'mobile mb-3': $vuetify.breakpoint.smAndDown,
          }"
          v-html="currentCard.mean"
        ></p>
        <div
          class="text-center"
          :class="{ 'mt-3': $vuetify.breakpoint.smAndUp }"
        >
          <p v-html="currentCard.exampleFormatted" class="example"></p>
        </div>
      </template>

      <template v-else-if="currentTypeCard == 5">
        <p
          class="mean ft400"
          :class="{ mobile: $vuetify.breakpoint.smAndDown }"
          v-html="formatBreakLineInEditor(currentCard.meanFormatted)"
          style="color: #1bb763"
        ></p>
        <div
          class="text-center"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-1'"
        >
          <p
            class="word ft400 word28"
            :class="{ mobile: $vuetify.breakpoint.smAndDown }"
            style="color: #3887fe"
            v-html="currentCard.wordFormatted"
          ></p>
        </div>
        <div class="text-center mt-3">
          <p v-html="currentCard.exampleFormatted" class="example"></p>
        </div>
      </template>

      <template v-else>
        <div class="mt-1 text-center">
          <p
            class="word ft400 word28"
            style="color: #1bb763"
            :class="{ 'mobile-type-23': $vuetify.breakpoint.smAndDown }"
            v-html="currentCard.wordFormatted"
          ></p>
        </div>
        <p
          class="mean ft400"
          :class="{
            'mobile-type-23 mt-3 mb-3': $vuetify.breakpoint.smAndDown,
            'mt-1': $vuetify.breakpoint.smAndUp,
          }"
          style="color: #3887fe"
          v-html="formatBreakLineInEditor(currentCard.meanFormatted)"
        ></p>
        <div
          class="text-center"
          :class="{ 'mt-3': $vuetify.breakpoint.smAndUp }"
        >
          <p v-html="currentCard.exampleFormatted" class="example"></p>
        </div>
      </template>
    </template>

    <ButtonComponent
      :title="PREVIEW_CARD_STEP_LIST[currentStepNo - 1].btnLabel"
      @click="changeStep"
      class="btn-switch"
      :class="{ 'fs-16': $vuetify.breakpoint.smAndDown }"
    />
  </div>
</template>

<script>
import InputComponent from "@/components/ui/InputComponent.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import { formatBreakLineInEditor } from "@/uttil";

export default {
  name: "CardPreviewContent",
  components: { InputComponent, ButtonComponent },
  props: {
    currentCard: {
      type: Object,
      default: () => {},
    },
    formType: {
      type: String,
      default: "ADD",
    },
  },
  data: () => ({
    currentStepNo: 1,
    answerInput: {
      placeholder: "Nhập câu trả lời của bạn",
      id: "answer",
    },
    answerValue: "",
    isFocus: false,
    currentTypeCard: null,
    formatBreakLineInEditor,
  }),
  computed: {
    isCardEngLishVietnamese() {
      return [1, 2].includes(this.currentCard.typeCard);
    },
    isCardVietnameseEngLish() {
      return [4, 5].includes(this.currentCard.typeCard);
    },
    currentStep() {
      return this.PREVIEW_CARD_STEP_LIST.find(
        (step) => step.number === this.currentStepNo
      );
    },
    PREVIEW_CARD_STEP_LIST() {
      if (this.formType === "EDIT") {
        if (this.isCardEngLishVietnamese) {
          return [
            {
              number: 1,
              title: "Mặt câu hỏi thẻ Anh - Việt",
              btnLabel: "Hiện mặt đáp án thẻ Anh - Việt",
              isFrontSide: true,
            },
            {
              number: 2,
              title: "Mặt đáp án thẻ Anh - Việt",
              btnLabel: "Hiện mặt câu hỏi thẻ Anh - Việt",
              isFrontSide: false,
            },
          ];
        } else if (this.isCardVietnameseEngLish) {
          return [
            {
              number: 1,
              title: "Mặt câu hỏi thẻ Việt - Anh",
              btnLabel: "Hiện mặt đáp án thẻ Việt - Anh",
              isFrontSide: true,
            },
            {
              number: 2,
              title: "Mặt đáp án thẻ Việt - Anh",
              btnLabel: "Hiện mặt câu hỏi thẻ Việt - Anh",
              isFrontSide: false,
            },
          ];
        } else
          return [
            {
              number: 1,
              title: "Mặt câu hỏi",
              btnLabel: "Hiện mặt đáp án",
              isFrontSide: true,
            },
            {
              number: 2,
              title: "Mặt đáp án",
              btnLabel: "Hiện mặt câu hỏi",
              isFrontSide: false,
            },
          ];
      } else {
        if (this.currentCard.typeCard === 3) {
          return [
            {
              number: 1,
              title: "Mặt câu hỏi",
              btnLabel: "Hiện mặt đáp án",
              isFrontSide: true,
            },
            {
              number: 2,
              title: "Mặt đáp án",
              btnLabel: "Hiện mặt câu hỏi",
              isFrontSide: false,
            },
          ];
        }
        return [
          {
            number: 1,
            title: "Mặt câu hỏi thẻ Anh - Việt",
            btnLabel: "Hiện mặt đáp án thẻ Anh - Việt",
            isFrontSide: true,
          },
          {
            number: 2,
            title: "Mặt đáp án thẻ Anh - Việt",
            btnLabel: "Hiện mặt câu hỏi thẻ Việt - Anh",
            isFrontSide: false,
          },
          {
            number: 3,
            title: "Mặt câu hỏi thẻ Việt - Anh",
            btnLabel: "Hiện mặt đáp án thẻ Việt - Anh",
            isFrontSide: true,
          },
          {
            number: 4,
            title: "Mặt đáp án thẻ Việt - Anh",
            btnLabel: "Hiện mặt câu hỏi thẻ Anh - Việt",
            isFrontSide: false,
          },
        ];
      }
    },
  },
  created() {
    this.currentTypeCard = this.currentCard.typeCard;
  },
  methods: {
    changeStep() {
      if (this.formType === "ADD" && [1, 2].includes(this.currentTypeCard)) {
        if (this.currentStepNo === 4) {
          this.currentStepNo = 1;
        } else {
          this.currentStepNo++;
        }
      } else if (this.formType === "EDIT" || this.currentTypeCard === 3) {
        this.currentStepNo = this.currentStepNo === 1 ? 2 : 1;
      }
    },
    handlePlayAudio() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer) {
        audioPlayer.muted = false;
        audioPlayer.currentTime = 0;
        audioPlayer.play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  z-index: 2;
}
* {
  color: #384961;
}
p {
  margin-bottom: 0px;
}
.container {
  padding: 30px;
  border-radius: 10px;
  border: 2px solid black;
  background-color: white;
  min-height: 400px;
  min-width: 350px;
}
.front-side {
  .word {
    font-size: 28px;
    font-weight: 700;
    color: #1bb763;
    white-space: pre-line;
    text-align: center;
    &.fw400 {
      font-weight: 400;
    }
    &.mobile {
      font-size: 22px;
      font-weight: 400;
      &.fw600 {
        font-weight: 600;
      }
    }
  }
  .word-type {
    font-weight: 600;
    font-size: 22px;
    &.mobile {
      font-size: 21px;
    }
  }
  .audio {
    gap: 5px;
    .spelling {
      font-weight: 600;
      font-size: 16px;
      color: #384961;
    }
  }
}

.mean {
  font-size: 28px;
  font-weight: 700;
  color: #3887fe;
  white-space: pre-line;
  text-align: center;
  &.mobile-type-23 {
    font-size: 21px;
    font-weight: 400;
  }
  &.mobile {
    font-size: 22px;
    font-weight: 600;
  }
  &.ft400 {
    font-weight: 400;
  }
  &.mean22 {
    font-size: 22px;
    font-weight: 600;
    color: #1bb763;
    &.ft400 {
      font-weight: 400;
    }
  }
  p {
    margin-bottom: 16px;
  }
}
.word {
  font-size: 22px;
  font-weight: 600;
  color: #1bb763;
  text-align: center;
  white-space: pre-line;
  &.mobile {
    font-size: 21px;
  }
  &.word28 {
    font-size: 28px;
    font-weight: 700;
    &.mobile {
      font-weight: 600;
      font-size: 21px;
      &.ft400 {
        font-weight: 400;
      }
    }
    &.mobile-type-23 {
      font-size: 22px;
      font-weight: 400;
    }
    &.ft400 {
      font-weight: 400;
    }
  }
  &.correct {
    color: #ffffff;
    background: #1bb763;
  }
  &.wrong {
    color: #384961;
    background: #cbd0dc;
  }
}
i.v-icon {
  &.correct {
    color: #ffffff;
    background: #1bb763;
  }
  &.wrong {
    color: #384961;
    background: #cbd0dc;
  }
}
.audio {
  z-index: 2;
  .img-wrapper {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  &.mobile {
    margin-top: 4px;
    .spelling {
      margin-left: 3px;
    }
  }
  .spelling {
    font-weight: 600;
    font-size: 16px;
    color: #384961;
    font-family: "Arial";
    margin-right: 10px;
    margin-left: 10px;
  }
  .word-type {
    font-weight: 600;
    font-size: 16px;
    color: #f56742;
  }
}
.example,
.example::v-deep p {
  font-size: 19px;
  line-height: 30px;
  margin-bottom: 0;
}
.fs-16 {
  &::v-deep h6 {
    font-size: 16px;
  }
}
.btn-switch {
  left: 50%;
  position: absolute;
  bottom: 30px;
  &::v-deep button {
    transform: translateX(-50%);
  }
  @media (min-width: 300px) {
    ::v-deep .v-btn {
      max-width: none;
    }
  }
}
</style>
